import React from "react";
import { Typography, CircularProgress } from "@mui/material";

const SyncingProcessWithText = ({ classes, text }) => {
    return (
        <Typography
            noWrap
            component="div"
            className={classes.typographyStyle2}
            style={{ paddingLeft: '10px', color: '#82cb43', lineHeight: '22px', display: "flex", alignItems: "center" }}
        >
            <CircularProgress
                className={classes.circular}
                style={{
                    color: '#82cb43',
                    width: '15px',
                    height: '15px',
                    display: 'flex',
                    marginLeft: '0px',
                    marginRight: '5px'
                }}
            />
            <span className="syncing-text" style={{ fontSize: "14px" }}>{text}</span>
        </Typography>
    );
};

export default SyncingProcessWithText;