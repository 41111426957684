/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import directus from "../../services/directus";
import useStateRef from "react-usestateref";
import moment from "moment";
import EventEmitter from "src/utils/EventEmitter";
import {
  formatNumber,
  customerFields
} from 'src/components/ReusableFunction';
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getEmployee } from 'src/utils/sessions';
import { OverflowTip } from "src/components/OverflowTip";
import { IconButton, Divider } from "@mui/material";
import ErrorMessage from 'src/components/ErrorMessage';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  theme,
  SubmitButton,
  CancelButton,
  AddItemButton,
  CustomButton,
  CustomButton2,
  StyledMenu,
} from 'src/components/ReusableStyle';
import {
  useStyles,
  NumberFormatCustom,
  NumberFormatCustomPercent,
} from './style.js';
import {
  Grid,
  Typography,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  CircularProgress,
  Chip,
  Tooltip,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import SyncingProcessWithText from 'src/components/SyncingProcessWithText';
import SyncIcon from '@mui/icons-material/Sync';
import { SyncSingleItemPrice } from './SyncItemPrices';
import InfoIcon from '@material-ui/icons/Info';
import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  calculateSubTotal, calculateTotal, calculateItemTotal,
  loadItems, loadCustomer,
  getDeliveryAddress, getConfigurationSettings,
  handleChangeDeliveryAddressOption,
  handleChangeAddress,
  formattingDeliveryAddress
} from './SalesOrderFunction';
import { useSyncDialogs } from './components/SyncPricesDialog';
import CustomerAutocomplete from 'src/views/SalesOrders/components/CustomerAutocomplete';
import SalesItemCodeDescriptionAutocomplete from 'src/views/SalesOrders/components/SalesItemCodeDescriptionAutocomplete';
import DeliveryAddressAutocomplete from 'src/views/SalesOrders/components/DeliveryAddressAutocomplete';
import GooglePlacesAddressAutocomplete from 'src/views/SalesOrders/components/GooglePlacesAddressAutocomplete';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "#929292"
  }
})(Tooltip);

const AddOrder = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [showTotalDetails, setShowTotalDetails] = useState(false);
  const [, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [notes, setNotes] = useState(null);
  const [, setDeliveryAddressOptionId, deliveryAddressOptionIdRef] = useStateRef(0);
  const [deliveryAddressOptionSelected, setDeliveryAddressOptionSelected] = useState([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState([{ id: -1, address_name: 'Custom', lable: 'Custom' }]);
  const [, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [, setSubTotal, subTotalRef] = useStateRef(0);
  const [, setTotalTax, totalTaxRef] = useStateRef(0);
  const [, setOrderDiscount, orderDiscountRef] = useStateRef(0);
  const [, setOrderDiscountPercent, orderDiscountPercentRef] = useStateRef(0);
  const [, setShipping, shippingRef] = useStateRef(0);
  const [, setSubTotalIncludingTax, subTotalIncludingTaxRef] = useStateRef(0);
  const [selectedDeliveryDate, handleDeliveryDateChange] = useState(new Date());
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [email, setEmail] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dataCustomEmail, setDataCustomEmail, dataCustomEmailRef] = useStateRef({ send_to_email: null, cc_email: null });
  const [customEmailLoading, setCustomEmailLoading] = useState(false);
  const [isCustomEmail, setIsCustomEmail] = useState(false);
  const [, setUploadFiles, uploadFilesRef] = useStateRef([]);
  const [anchorElOrdersType, setAnchorElOrdersType] = React.useState(null);
  const openActions = Boolean(anchorElOrdersType);

  const [itemsLoading, setItemsLoading] = useState(false);
  const [, setItemsOptions, itemsOptionsRef] = useStateRef([]);

  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  const [, setCustomerOptions, customerOptionsRef] = useStateRef([]);

  const [items, setItems, itemsRef] = useStateRef([]);
  const [itemCheck, setItemCheck] = useState(false);
  const [, setItemCount, itemCountRef] = useStateRef(false);

  const [, setInputAddressValue, inputAddressValueRef] = useStateRef('');
  const [, setSelectedDeliveryAddress, selectedDeliveryAddressRef] = useStateRef({
    AddressLine1: '',
    AddressLine2: '',
    AddressName: '',
    AddressCity: '',
    AddressState: '',
    AddressPostcode: '',
  });

  const [, setSyncLoading, syncLoadingRef] = useStateRef(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [, setSelectedSyncItem, selectedSyncItemRef] = useStateRef(null);

  const [, setEnableItemDiscountPercent, isEnableItemDiscountPercentRef] = useStateRef(false);
  const [, setMaxOrderItemDiscountPercent, maxOrderItemDiscountPercentRef] = useStateRef(0);
  const [, setMaxOrderDiscount, maxOrderDiscountRef] = useStateRef(0);
  const { SyncDialogs, setDialogSyncAllOpen, setDialogSyncItemOpen } =
    useSyncDialogs(itemsRef, selectedCustomerRef, setItems, setSyncLoading, selectedSyncItemRef, moment().format('YYYY-MM-DD HH:mm:ss'), true);

  const [, setOpenChooseLocationDialog, openChooseLocationDialogRef] = useStateRef(false);
  const [, setLocations, locationsRef] = useStateRef([]);
  const [, setSelectedLocation, selectedLocationRef] = useStateRef("");
  const [, setLoadingItemStockLocation, loadingItemStockLocationRef] = useStateRef(false);
  const [, setSelectedItem, selectedItemRef] = useStateRef("");

  const isMounted = useRef(true);

  // Prevent page reload when the dialog is open
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (syncLoadingRef.current) {
        event.preventDefault();
        event.returnValue = ""; // Standard for modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [syncLoadingRef.current]);

  useEffect(() => {
    isMounted.current = true; // component mount
    if (!initialized) {
      setInitialized(true);
      loadCustomer(
        customerFields,
        isMounted,
        setCustomerOptions,
        enqueueSnackbar,
      );

      loadItems(
        setItemsLoading,
        setItemsOptions,
        enqueueSnackbar
      );
      getConfigurationSettings(
        isMounted,
        setShowTotalDetails,
        setEnableItemDiscountPercent,
        setMaxOrderItemDiscountPercent,
        setMaxOrderDiscount
      );
      addItems();
    }

    return () => {
      isMounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'notes') {
      setNotes(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    } else if (name === 'delivery_address') {
      setDeliveryAddress(value);
    } else if (name === 'order_discount') {
      const new_value = value ? parseFloat(value) : 0;
      setOrderDiscount(new_value);
      countTotal();
    } else if (name === 'order_discount_percent') {
      if (value === "") {
        setOrderDiscountPercent("");
        return;
      }

      // Allow numbers with up to 3 decimal places
      if (!/^\d*\.?\d{0,3}$/.test(value)) {
        return; // Prevent invalid input
      }


      let sub_total = calculateSubTotal(itemsRef.current);

      var new_value = value ? value : 0;

      if (new_value <= 100) {
        setOrderDiscountPercent(new_value); // Store as raw number

        if (new_value) {
          var discount = (new_value / 100) * sub_total;

          setOrderDiscount(parseFloat(discount).toFixed(2));
        } else {
          setOrderDiscount(0);
        }

        countTotal(true);
      }
    } else if (name === 'shipping') {
      var new_value2 = value ? parseInt(value) : 0;
      setShipping(new_value2);
      countTotal();
    }
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const editSubmit = async (type, send_customer = false, custom_send = false) => {
    if (type == "sent") {
      var show_limit_error_message = false;

      var error_message = "";

      if (maxOrderItemDiscountPercentRef.current && isEnableItemDiscountPercentRef.current) {
        itemsRef.current.forEach((data) => {
          if (data.item_discount && data.statusValue !== 3 && maxOrderItemDiscountPercentRef.current < data.item_discount) {
            show_limit_error_message = true;
            error_message += `- Item discount for ${data.internal_code ? "'" + data.internal_code + "'" : "an item"} exceeds the limit of `;
            error_message += formatNumber((maxOrderItemDiscountPercentRef.current).toFixed(2)) + "%.\n";
          }
        });
      }

      if (maxOrderDiscountRef.current && maxOrderDiscountRef.current < orderDiscountRef.current) {
        show_limit_error_message = true;
        error_message += `- Order discount cannot exceed $${formatNumber(maxOrderDiscountRef.current.toFixed(2))}.\n`;
      }

      if (show_limit_error_message) {
        error_message += "Please adjust your discount accordingly.";
        window.alert(error_message);
        return false;
      }

    }

    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    setLoadingSubmit(true);

    var orderResult = null;

    if (custom_send) {
      orderResult = await directus.api.post(
        `/items/sales_orders`,
        {
          employee: directusUser.id,
          status: type,
          order_date: date,
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          farm_code: selectedCustomerRef.current ? selectedCustomerRef.current.id : null,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
          send_to_email: dataCustomEmailRef.current.send_to_email,
          cc_email: dataCustomEmailRef.current.cc_email,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current,
          notes: notes,
        },
        { send_customer: send_customer, custom_send: custom_send }
      );
    } else {
      orderResult = await directus.api.post(
        `/items/sales_orders`,
        {
          employee: directusUser.id,
          status: type,
          order_date: date,
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          cc_email: email,
          notes: notes,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current,
          farm_code: selectedCustomerRef.current ? selectedCustomerRef.current.id : null,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null
        },
        { send_customer: send_customer }
      );
    }

    //--------------------------------------------------------------------------------------------------------
    let addItems = [];

    //eslint-disable-next-line array-callback-return
    itemsRef.current.map((data, index) => {
      var location_code = "";

      if (data.selected_location) {
        if (data.selected_location.LocationCode) {
          location_code = data.selected_location.LocationCode;
        }
      }

      addItems = [
        ...addItems,
        {
          id: uuidv4(),
          sales_order: { id: orderResult.data.id },
          sales_order_item: {
            id: data.id,
            description: data.description,
            quantity: data.quantity,
            unit_price: data.unit_price,
            sort: index,
            internal_code: data.internal_code,
            item_discount: data.item_discount ?? 0,
            sales_item: data.sales_item ? data.sales_item.id : null,
            location_code: location_code
          }
        }
      ];
    });

    if (addItems.length > 0) {
      var addItemsBody = addItems;

      for (let index = 0; index < addItemsBody.length; index++) {
        var addItem = addItemsBody[index];
        delete addItem.id;
        delete addItem.sales_order_item.id;

        var orderItemResult = await directus.createItems('sales_order_items', addItem.sales_order_item);
        addItem.sales_order_item = { id: orderItemResult.data.id };

        addItemsBody[index] = addItem;
      }

      await directus.createItems('sales_orders_items', addItemsBody);
    }

    if (uploadFilesRef.current && uploadFilesRef.current.length > 0) {
      const promises = uploadFilesRef.current.map(async value => {
        if (value.formData) {
          let updateData = await directus.uploadFiles(value.formData, onUploadProgress);

          await directus.createItem('sales_order_files', {
            status: 'published',
            sales_order_id: orderResult.data.id,
            directus_file_id: updateData.data.id
          });
        }
      });

      await Promise.all(promises);
    }

    setLoadingSubmit(false);

    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
    handleCloseActions();
    EventEmitter.emit('load_all_orders', { value: true });
  };

  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  const changeDeliveryAddressOption = newValue => {
    handleChangeDeliveryAddressOption(
      newValue,
      deliveryAddressOptionIdRef,
      setDeliveryAddress,
      setDeliveryAddressOptions,
      null,
      setSelectedDeliveryAddress
    );
  }

  const onChangeAddress = async (data) => {
    await handleChangeAddress(
      data,
      setDeliveryAddressOptionSelected,
      setInputAddressValue,
      setSelectedDeliveryAddress,
      selectedDeliveryAddressRef
    );
  }

  const countTotal = (skipDiscountPercent = false) => {
    calculateTotal(
      itemsRef.current,
      orderDiscountRef.current,
      shippingRef.current,
      setSubTotalIncludingTax,
      setSubTotal,
      setTotalTax,
      setTotalOrderItem,
      setOrderDiscount,
      setOrderDiscountPercent,
      skipDiscountPercent
    );
  };

  const addItemsCheck = itemCount => {
    var item_check = true;

    itemsRef.current.forEach(data => {
      if (data.statusValue !== 3) {
        if (itemCount === 0) {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            item_check = false;
          }
        } else {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            item_check = false;
          }
        }
      }
    });

    setItemCheck(item_check);
  };

  const changeItems = (e, oldData, newData) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }

    if (name == "item_discount" && !isEnableItemDiscountPercentRef.current) return false;

    setItems(
      items.map(dataList =>
        dataList.id === oldData.id
          ? { ...oldData, [name]: value }
          : dataList
      )
    );

    countTotal();
    addItemsCheck(itemCountRef.current);

    // Clear existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to delay the function execution
    if (name === "quantity") {
      setTypingTimeout(setTimeout(() => {
        if (oldData && !Array.isArray(oldData)) {
          oldData["quantity"] = value;
          syncItem(oldData);
        }
      }, 1000));
    }
  };

  const deleteItems = itemData => {
    setItems(itemsRef.current.filter(data => data.id !== itemData.id));

    deleteItemsCheck(itemData);
    addItemsCheck(itemCountRef.current);
    countTotal();
  };

  const addItems = () => {
    let data = [];
    data.id = uuidv4();
    if (itemsRef.current && itemsRef.current.length > 0) {
      data.count = itemsRef.current[itemsRef.current.length - 1].count + 1;
      setItems([...itemsRef.current, data]);
      setItemCount(data.count);
    } else {
      let data = [];
      data.id = uuidv4();
      data.count = 0;
      setItems([data]);
      setItemCount(data.count);
    }

    addItemsCheck(itemCountRef.current);
  };

  const deleteItemsCheck = itemData => {
    let items = [];
    items = itemsRef.current.filter(data => data.count !== itemData.count);

    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.quantity < 0 ||
      items.description == undefined
    ) {
      setItemCheck(false);
    }
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmailRef.current, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmailRef.current.cc_email;
    var send_to_email = dataCustomEmailRef.current.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit('sent', false, true);
    }
  };

  const openCustomEmail = async () => {
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    var employee_data = await getEmployee();
    if (employee_data.email) {
      cc_email = employee_data.email;
    } else if (employee_data.user.email) {
      cc_email = employee_data.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const handleCloseActions = () => {
    setAnchorElOrdersType(null);
  };

  const handleOpenActions = event => {
    setAnchorElOrdersType(event.currentTarget);
  };

  const handleFileDelete = index => {
    var current_files = uploadFilesRef.current;
    var new_upload_files = [];

    current_files.map((value, file_index) => {
      if (file_index != index) {
        new_upload_files.push(value);
      }
    });

    setUploadFiles(new_upload_files);
  };

  const handleSubmission = async (e) => {
    try {
      handleCloseActions(false);
      e.preventDefault();


      var file_data = document.getElementById('fileInput');
      const form = document.querySelector('#my-form');
      const data = new FormData(form);


      var current_files = uploadFilesRef.current;
      var new_upload_files = [];
      new_upload_files = [
        ...current_files.concat({
          name: file_data.files.item(0).name,
          formData: data
        })
      ];

      setUploadFiles(new_upload_files);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  var disabledCheck = selectedCustomerRef.current === null || itemCheck === false;

  const handleChangeSalesItem = async (item, oldData) => {
    setItems(
      itemsRef.current.map(dataList =>
        dataList.id === oldData.id
          ? dataList.statusValue === 1
            ? { ...oldData, statusValue: 1, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
            : { ...oldData, statusValue: 2, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
          : dataList
      )
    );
    addItemsCheck(itemCountRef.current);
    countTotal();

    oldData.internal_code = item.item_number;
    oldData.sales_item = item;
    await syncItem(oldData);
    getItemStockLocations(item, oldData);
  }

  let delivery_address_check = deliveryAddressOptionIdRef.current == -1 ? inputAddressValueRef.current : selectedDeliveryAddressRef.current.AddressLine1;

  const syncItemConfirmation = async (itemData) => {
    setSelectedSyncItem(itemData);
    setDialogSyncItemOpen(true);
  };

  const syncItem = async (itemData) => {
    setSyncLoading(true);
    var updatedItems = await SyncSingleItemPrice(itemData, itemsRef.current, selectedCustomerRef.current, moment().format('YYYY-MM-DD HH:mm:ss'), true);
    setItems(updatedItems);
    setSyncLoading(false);
  }

  const showChooseLocationDialog = async (itemData) => {
    if (itemData.internal_code && !openChooseLocationDialogRef.current) {
      setSelectedItem(itemData.id);
      setOpenChooseLocationDialog(true);

      setLocations(itemData.stock_locations || []);
      setSelectedLocation(itemData.selected_location || null);
    }
  }

  const closeChooseLocationsDialog = () => {
    setOpenChooseLocationDialog(false);
    setSelectedLocation("");
    setLocations([]);
  }

  const saveLocation = () => {
    if (!selectedLocationRef.current || !selectedItemRef.current) return;

    // Create a new updated list, ensuring itemsRef.current remains unchanged
    const updatedItems = itemsRef.current.map(item =>
      item.id === selectedItemRef.current
        ? { ...item, selected_location: selectedLocationRef.current }
        : item
    );

    setItems(updatedItems);
    closeChooseLocationsDialog();
  };

  const getTotalStock = () => {
    var total = 0;
    locationsRef.current.map((loc) => {
      total += loc.Amount ?? 0
    })

    return total;
  }

  const getItemStockLocations = async (item, oldData) => {
    try {
      setLoadingItemStockLocation(true);
      setSelectedItem(oldData.id);
      const response = await directus.api.post(`/custom/sales_items/locations`, {
        Items: [item.item_number],
      });
      // Merge stock locations with existing items
      const updatedItems = mergeItemsWithStockLocations(response, item, oldData);
      setItems(updatedItems);

    } catch (error) {
      console.error("Error fetching locations:", error);
    }

    setLoadingItemStockLocation(false);
  }

  // Separate function to merge stock locations
  const mergeItemsWithStockLocations = (codeLocationList, item, oldData) => {
    return itemsRef.current.map(existingItem => {
      if (existingItem.id === oldData.id) {
        const stockLocations = codeLocationList[item.item_number] || [];
        const selectedLocation = stockLocations.find(location => location.Default) || null;

        return {
          ...existingItem,
          stock_locations: stockLocations,
          selected_location: selectedLocation
        };
      }

      // If no match, return the item unchanged
      return existingItem;
    });
  };

  const getStockColour = (dataItem) => {
    var color = "#ff4f4f";
    if (dataItem.selected_location && dataItem.selected_location.Amount) {
      color = "#82cb43";
    }

    return color;
  }

  const handleLocationChange = (e) => {
    const selectedCode = e.target.value;
    const selectedLoc = locationsRef.current.find(loc => loc.LocationCode === selectedCode) || null;
    setSelectedLocation(selectedLoc);
  };

  return (
    <>
      <Grid container spacing={0} justifyContent="space-around">
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography noWrap component="div" style={{ paddingRight: '20px', fontWeight: '590', fontSize: '20px' }}>
            <OverflowTip>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '5px' }}>
                Sales Order
                <InsertDriveFileIcon
                  style={{
                    color: 'white',
                    backgroundColor: '#516573',
                    padding: '3px',
                    borderRadius: '50%',
                    fontSize: '20px',
                    marginRight: '5px'
                  }}
                />

                <CustomButton2
                  variant="contained"
                  style={{ fontSize: "14px", lineHeight: "14px", height: "24px", borderRadius: "10px" }}
                  onClick={() => setDialogSyncAllOpen(true)}
                  disabled={syncLoadingRef.current || itemsLoading || loadingSubmit}
                >
                  Sync Prices
                </CustomButton2>


                {syncLoadingRef.current ?
                  <SyncingProcessWithText classes={classes} text={"syncing"} />
                  : ''}
              </div>
            </OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.flexEnd}>
          <OverflowTip>
            <div style={{ display: 'flex', columnGap: '10px' }}>
              <IconButton aria-label="delete" size="small" style={{ height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', paddingRight: '20px', paddingLeft: '20px' }}>
                <img src='/static/images/icons/draft.svg' alt='Draft Status Icon' />
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ paddingLeft: '10px', color: '#374355', lineHeight: '22px', textTransform: 'capitalize' }}
                >
                  <OverflowTip>Draft</OverflowTip>
                </Typography>
              </IconButton>
            </div>
          </OverflowTip>
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
        <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px' }}>
          <>
            {/* -------------------- CUSTOMER DETAILS -------------------- */}
            <div className='supplier'>
              <Typography
                noWrap
                component="div"
                className={classes.typographyStyle}
                style={{ paddingLeft: '16px' }}
              >
                <OverflowTip>Customer Details</OverflowTip>
              </Typography>
              <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', padding: '5px' }}>
                <Grid container spacing={0}>
                  <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Customer Name</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <CustomerAutocomplete
                      customerOptionsRef={customerOptionsRef}
                      classes={classes}
                      selectedCustomerRef={selectedCustomerRef}
                      isClickFavouriteRef={isClickFavouriteRef}
                      setIsClickFavourite={setIsClickFavourite}
                      enqueueSnackbar={enqueueSnackbar}
                      setSelectedCustomer={setSelectedCustomer}
                      setDeliveryAddressOptionId={setDeliveryAddressOptionId}
                      setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                      changeDeliveryAddressOption={changeDeliveryAddressOption}
                      itemsRef={itemsRef}
                      setDialogSyncAllOpen={setDialogSyncAllOpen}
                      setCustomerOptions={setCustomerOptions}
                      checkCurrentSelectedCustomer={false}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        </Grid>
        {/* -------------------- DATE -------------------- */}
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <div className='supplier'>
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ paddingLeft: '16px' }}
            >
              <OverflowTip>Date</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography
                noWrap
                component="div"
                className={classes.typographyStyle2}
                style={{ paddingLeft: '16px' }}
              >
                <OverflowTip>{moment(Date.now()).format('DD MMMM YYYY')}</OverflowTip>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* -------------------- TABLE HEADER -------------------- */}
      <Grid container spacing={0}>
        <Grid item style={{ width: 'calc(100% - 90px)' }}>
          <Grid container spacing={0} justifyContent="space-around" style={{ paddingLeft: '5px', marginTop: '20px' }}>

            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
              <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                <OverflowTip>Code</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
              <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                <OverflowTip>Description</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
              <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                <OverflowTip>Qty</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
              <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                <OverflowTip>Unit Price</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
              <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px', display: "flex", alignItems: "center" }}>
                <OverflowTip>Item Discount</OverflowTip>
                {maxOrderItemDiscountPercentRef.current && isEnableItemDiscountPercentRef.current ?
                  <CustomTooltip data-testid="showMaxDiscount" title={<Typography variant="h6">Max discount: {maxOrderItemDiscountPercentRef.current}%</Typography>}>
                    <InfoIcon
                      fontSize="small"
                      style={{ cursor: 'pointer', height: '15px', marginTop: "2px" }}
                    />
                  </CustomTooltip>
                  : ''
                }
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
              <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px', paddingRight: '16px', textAlign: 'right' }}>
                <OverflowTip>Total Price</OverflowTip>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* -------------------- TABLE BODY -------------------- */}
      {itemsRef.current ?
        itemsRef.current.map((dataItem, index) => {
          const isLastItem = index === itemsRef.current.length - 1;

          return (
            <Grid key={index} container data-testid='OrderLines' spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
              <Grid item style={{ width: 'calc(100% - 90px)', background: 'white', paddingLeft: '5px', borderRadius: '10px' }}>
                <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    {itemsLoading ?
                      <div style={{ margin: '18px 11px' }}>
                        <LinearProgress />
                      </div>
                      :
                      <SalesItemCodeDescriptionAutocomplete
                        index={index}
                        itemsOptionsRef={itemsOptionsRef}
                        classes={classes}
                        dataItem={dataItem}
                        handleChangeSalesItem={handleChangeSalesItem}
                        name={"code"}
                      />
                    }
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                    {itemsLoading ?
                      <div style={{ margin: '18px 11px' }}>
                        <LinearProgress />
                      </div>
                      :
                      <SalesItemCodeDescriptionAutocomplete
                        index={index}
                        itemsOptionsRef={itemsOptionsRef}
                        classes={classes}
                        dataItem={dataItem}
                        handleChangeSalesItem={handleChangeSalesItem}
                        name={"description"}
                      />
                    }
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                    <TextField
                      id={`order_item_quantity_${index}`}
                      variant='outlined'
                      className={
                        dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                          ? classes.textfield3
                          : classes.textfield3Required
                      }
                      fullWidth
                      name="quantity"
                      type='number'
                      inputProps={{
                        'aria-label': 'Quantity',
                      }}
                      value={dataItem.quantity ? dataItem.quantity : ''}
                      onChange={event => changeItems(event, dataItem)}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <TextField
                      id={`order_item_unit_price_${index}`}
                      variant='outlined'
                      className={classes.textfield3}
                      fullWidth
                      name="unit_price"
                      value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : ''}
                      onChange={event => changeItems(event, dataItem)}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true,
                        'aria-label': 'Unit Price',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <TextField
                      id={`order_item_discount_${index}`}
                      variant='outlined'
                      className={
                        maxOrderItemDiscountPercentRef.current && isEnableItemDiscountPercentRef.current ?
                          maxOrderItemDiscountPercentRef.current < (dataItem.item_discount ? dataItem.item_discount : 0)
                            ?
                            classes.textfield3Required
                            :
                            classes.textfield3 : classes.textfield3
                      }
                      fullWidth
                      name="item_discount"
                      value={dataItem.item_discount ? dataItem.item_discount : 0}
                      onChange={event => changeItems(event, dataItem)}
                      InputProps={{
                        inputComponent: NumberFormatCustomPercent,
                        'aria-label': 'Item Discount',
                        readOnly: !isEnableItemDiscountPercentRef.current
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <TextField
                      id={`order_item_total_price_${index}`}
                      variant='outlined'
                      className={classes.textfield4}
                      fullWidth
                      name="total_price"
                      value={calculateItemTotal(dataItem.quantity, dataItem.unit_price, dataItem.item_discount)}
                      inputProps={{
                        readOnly: true,
                        'aria-label': 'Total Price',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                <IconButton onClick={() => deleteItems(dataItem)} aria-label="delete" size="small" style={{ width: '22px', height: '22px' }} >
                  <CustomTooltip arrow title={<Typography variant="h6">Delete order item</Typography>}>
                    <img src='/static/images/icons/remove.svg' alt='Remove Icon' />
                  </CustomTooltip>
                </IconButton>
              </Grid>
              <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>

                {syncLoadingRef.current || itemsLoading || loadingSubmit ?
                  <IconButton
                    aria-label="sync" size="small" style={{ width: '22px', height: '22px' }} >
                    <CustomTooltip arrow title={<Typography variant="h6">Sync item's price</Typography>}>
                      <SyncIcon style={{ color: "#ababab" }} />
                    </CustomTooltip>
                  </IconButton>
                  :
                  <IconButton onClick={() => syncItemConfirmation(dataItem)}
                    aria-label="sync" size="small" style={{ width: '22px', height: '22px' }} >
                    <CustomTooltip arrow title={<Typography variant="h6">Sync item's price</Typography>}>
                      <SyncIcon style={{ color: "#82cb43" }} />
                    </CustomTooltip>
                  </IconButton>

                }

              </Grid>
              <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                {dataItem && dataItem.internal_code ?
                  loadingItemStockLocationRef.current && selectedItemRef.current == dataItem.id ?
                    <CircularProgress size={18} style={{ color: "#82cb43" }} />
                    :
                    <IconButton onClick={() => showChooseLocationDialog(dataItem)}
                      aria-label="stock_location" size="small" style={{ width: '22px', height: '22px' }}>
                      <CustomTooltip arrow title={<Typography variant="h6">
                        Stock Location: {dataItem.selected_location ? dataItem.selected_location.LocationCode ? dataItem.selected_location.LocationCode : dataItem.selected_location.LocationName ? dataItem.selected_location.LocationName : "-" : "-"}
                        <br />
                        <span style={{ fontSize: "12px" }}>Stock Level: {dataItem.selected_location ? dataItem.selected_location.Amount ? dataItem.selected_location.Amount : "Out of Stock" : "Out of Stock"}</span>
                        <br /><span style={{ fontSize: "11px" }}>Click to choose a different location</span></Typography>}>
                        <ApartmentIcon style={{ color: getStockColour(dataItem), fontSize: "22px" }} />
                      </CustomTooltip>
                    </IconButton>
                  :
                  <IconButton aria-label="stock_location" size="small" style={{ width: '22px', height: '22px' }} >
                    <CustomTooltip arrow title={<Typography variant="h6">Stock Location: - <br /><span style={{ fontSize: "11px" }}>Select an item first</span></Typography>}>
                      <ApartmentIcon style={{ color: "#a9a9af99", fontSize: "22px" }} />
                    </CustomTooltip>
                  </IconButton>
                }
              </Grid>
            </Grid >
          );
        })
        : ''}

      <SyncDialogs />

      {/* -------------------- ADD ITEM -------------------- */}
      <div style={{ display: 'flex', paddingLeft: '6px', marginTop: '15px' }}>
        <AddItemButton
          variant="outlined"
          onClick={addItems}
          disabled={syncLoadingRef.current}
          startIcon={<img src='/static/images/icons/add-item.svg' alt='Add Icon' />}
        >
          Add Item
        </AddItemButton>
      </div>

      {/* -------------------- DELIVERY -------------------- */}
      <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
        <Grid item xs={7} sm={7} md={7} lg={7}>
          <Typography
            noWrap
            component="div"
            className={classes.typographyStyle}
            style={{ marginTop: '17px', paddingLeft: '16px' }}
          >
            <OverflowTip>Delivery</OverflowTip>
          </Typography>
          <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px 0px 5px 5px' }}>
            <Grid container spacing={0} style={{ paddingBottom: '5px', paddingRight: '5px' }}>
              <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery by</Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <ThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.datepickerEditable}
                      inputVariant='outlined'
                      id="date_picker"
                      inputProps={{
                        readOnly: true,
                      }}
                      format="dd MMMM yyyy"
                      placeholder="dd/MM/yyyy"
                      value={moment(selectedDeliveryDate).format()}
                      onChange={date => handleDeliveryDateChange(date)}
                      keyboardIcon={<img src='/static/images/icons/calendar.svg' alt='Calendar Icon' />}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
            </Grid>
            <div style={{ backgroundColor: 'white' }}>
              <Divider style={{ marginLeft: '11px' }} />
            </div>
            <Grid container spacing={0} style={{ padding: '5px 5px 5px 0px' }}>
              <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery instructions</Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <TextField
                  variant="outlined"
                  className={classes.textfield2Editable}
                  value={deliveryInstructions ?? ''}
                  placeholder='Add delivery notes'
                  name="delivery_instructions"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <div style={{ backgroundColor: 'white' }}>
              <Divider style={{ marginLeft: '11px' }} />
            </div>
            <Grid container spacing={0} style={{ padding: '5px 5px 0px 0px' }}>
              <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery address</Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <DeliveryAddressAutocomplete
                  deliveryAddressOptions={deliveryAddressOptions}
                  classes={classes}
                  deliveryAddress={deliveryAddress}
                  setDeliveryAddress={setDeliveryAddress}
                  formattingDeliveryAddress={formattingDeliveryAddress}
                  selectedOrder={null}
                  setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                  setDeliveryAddressOptionId={setDeliveryAddressOptionId}
                  setDeliveryAddressOptionSelected={setDeliveryAddressOptionSelected}
                  setInputAddressValue={setInputAddressValue}
                />
              </Grid>
              {deliveryAddressOptionIdRef.current === -1 ?
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <GooglePlacesAddressAutocomplete
                    inputAddressValue={inputAddressValueRef.current}
                    setInputAddressValue={setInputAddressValue}
                    onChangeAddress={onChangeAddress}
                    deliveryAddressOptionSelected={deliveryAddressOptionSelected}
                  />
                </Grid>
                :
                selectedCustomer && (deliveryAddressOptionIdRef.current === 0 || deliveryAddressOptionIdRef.current > 0) ?
                  <Grid container spacing={0} style={{ padding: '15px 5px 10px 10px', color: '#3c3c4399', fontWeight: 700 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {getDeliveryAddress(deliveryAddress)}
                    </Grid>
                  </Grid>
                  : ''
              }
            </Grid>
          </div>



          {/* Notes */}
          <Typography
            noWrap
            component="div"
            className={classes.typographyStyle}
            style={{ marginTop: '17px', paddingLeft: '16px' }}
          >
            <OverflowTip>Notes</OverflowTip>
          </Typography>
          <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px' }}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  variant="outlined"
                  className={`${classes.textfield2Editable} ${classes.leftAlignTextfield}`}
                  value={notes ?? ''}
                  placeholder='Insert note...'
                  name="notes"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* --------------------- TOTALS --------------------- */}
        <Grid item xs={5} sm={5} md={5} lg={5} >
          <Typography
            noWrap
            component="div"
            className={classes.typographyStyle}
            style={{ marginTop: '17px', paddingLeft: '16px' }}
          >
            <OverflowTip>Totals</OverflowTip>
          </Typography>
          <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
            {showTotalDetails ?
              <>
                <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                  <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                    <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                    <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                      <OverflowTip>
                        {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                      </OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                  <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                    <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                    <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                      <OverflowTip>
                        {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                      </OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ marginLeft: '16px' }} />
                <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '16px' }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle4}
                      style={{ lineHeight: '22px' }}
                    >
                      <OverflowTip>Subtotal</OverflowTip>
                    </Typography>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle2}
                      style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                    >
                      <OverflowTip>Including tax</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle4}
                      style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                    >
                      <OverflowTip>
                        {isNaN(subTotalIncludingTaxRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalIncludingTaxRef.current * 100) / 100).toFixed(2))}
                      </OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ marginLeft: '16px' }} />
              </>
              :
              <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                  <Typography className={classes.typographyStyle4} style={{ color: 'black' }}>Subtotal</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                  <Typography className={classes.typographyStyle4} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                    <OverflowTip>
                      {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                    </OverflowTip>
                  </Typography>
                </Grid>
              </Grid>
            }
            <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount</Typography>
                  {maxOrderDiscountRef.current ?
                    <CustomTooltip data-testid="showMaxDiscount" title={<Typography variant="h6">Max discount: ${formatNumber((maxOrderDiscountRef.current).toFixed(2))}</Typography>}>
                      <InfoIcon
                        fontSize="small"
                        style={{ cursor: 'pointer', height: '15px' }}
                      />
                    </CustomTooltip>
                    : ''
                  }
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <TextField
                  variant="outlined"
                  className={
                    maxOrderDiscountRef.current ? maxOrderDiscountRef.current < orderDiscountRef.current
                      ?
                      classes.textfield2EditableRequired
                      : classes.textfield2Editable : classes.textfield2Editable
                  }
                  value={orderDiscountRef.current ? parseFloat(orderDiscountRef.current).toFixed(2) : ''}
                  name="order_discount"
                  data-testid="order-discount"
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount %</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <TextField
                  variant="outlined"
                  className={
                    maxOrderDiscountRef.current ? maxOrderDiscountRef.current < orderDiscountRef.current
                      ?
                      classes.textfield2EditableRequired
                      : classes.textfield2Editable : classes.textfield2Editable
                  }
                  value={
                    orderDiscountPercentRef.current !== null && orderDiscountPercentRef.current !== undefined
                      ? String(orderDiscountPercentRef.current)
                      : '0'
                  }
                  name="order_discount_percent"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Shipping</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <TextField
                  variant="outlined"
                  className={classes.textfield2Editable}
                  value={shippingRef.current ? parseFloat(shippingRef.current).toFixed(2) : ''}
                  name="shipping"
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
            </Grid>
            <Divider style={{ marginLeft: '16px' }} />
            <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '5px' }}>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle4}
                  style={{ lineHeight: '22px' }}
                >
                  <OverflowTip>Total</OverflowTip>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle4}
                  style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                >
                  <OverflowTip>
                    {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                  </OverflowTip>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* -------------------- BUTTON -------------------- */}
      <div style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
        <div style={{ width: '50%', flexWrap: 'wrap', display: 'flex', gap: '10px', overflowX: 'auto' }}>
          {uploadFilesRef.current && uploadFilesRef.current.length > 0
            ? uploadFilesRef.current.map((file, index) => {
              return (
                <Grid key={index} span="true" style={{ display: 'flex' }}>
                  <Chip
                    icon={<InsertDriveFileIcon />}
                    label={file.name}
                    onDelete={() => handleFileDelete(index)}
                    variant="outlined"
                    style={{ marginRight: '5px' }}
                  />
                </Grid>
              );
            })
            : ''}
        </div>
        <div style={{ width: '50%', maxWidth: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px', flexWrap: 'wrap' }}>
          {loadingSubmit ? (
            <span display="inline-flex" style={{ marginTop: '5px' }}>
              <CircularProgress size={20} />
            </span>
          ) : (
            <>
              <CustomButton
                variant="contained"
                onClick={handleOpenActions}
              >
                Actions
              </CustomButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElOrdersType}
                keepMounted
                open={Boolean(openActions)}
                onClose={handleCloseActions}
                className={classes.listStyle}
              >
                <List>
                  <MenuItem className={classes.menuitem2} onClick={() => { }} >
                    <form id="my-form" className={classes.form}>
                      <input type="file" name="file" id="fileInput" onChange={e => handleSubmission(e)} className={classes.input} />
                      <label htmlFor="fileInput" className={classes.fileInputLabel}>Attach File</label>
                    </form>
                  </MenuItem>
                  {selectedCustomer ? (
                    selectedCustomer.id ? (
                      selectedCustomer.email ? (
                        <MenuItem
                          className={classes.menuitem}
                          disabled={disabledCheck || !delivery_address_check}
                          onClick={() => editSubmit('sent', true)}
                        >
                          Submit and Send
                        </MenuItem>
                      ) : null
                    ) : null
                  ) : null}

                  {selectedCustomer ? (
                    selectedCustomer.id ? (
                      selectedCustomer.email ? (
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => openCustomEmail()}
                          disabled={disabledCheck || !delivery_address_check}
                        >
                          Submit and Custom Send
                        </MenuItem>
                      ) : null
                    ) : null
                  ) : null}
                </List>
              </StyledMenu>

              <CustomButton
                variant="contained"
                onClick={() => editSubmit('draft')}
              >
                Save Draft
              </CustomButton>
              <CustomButton2
                variant="contained"
                onClick={() => editSubmit('sent')}
                disabled={disabledCheck || !delivery_address_check}
              >
                Submit
              </CustomButton2>
            </>
          )}
        </div>
      </div>

      <Dialog open={isCustomEmail} onClose={closeCustomEmail}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Submit and Custom Send</h2>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px' }}>
          <TextField
            id="email_text"
            label="Email"
            variant="outlined"
            fullWidth={true}
            name="send_to_email"
            value={dataCustomEmail.send_to_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
          <br />
          <br />
          <TextField
            id="cc_email_text"
            label="CC Email"
            variant="outlined"
            fullWidth={true}
            name="cc_email"
            value={dataCustomEmail.cc_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
        </DialogContent>

        <DialogActions style={{ height: '60px', marginRight: '5px' }}>
          {customEmailLoading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => sendEmail()}
                disabled={!dataCustomEmail.send_to_email}
              >
                Save
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openChooseLocationDialogRef.current} onClose={closeChooseLocationsDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title" style={{ padding: "20px 30px 16px 30px" }}>
          <Typography component="div" style={{ fontWeight: "800", fontSize: "16px" }}>
            Stock Locations
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px', padding: "8px 30px" }}>
          <Grid container spacing={1} style={{ fontWeight: "bold", marginBottom: "5px" }}>
            <Grid item xs={1}></Grid> {/* Empty for radio buttons */}
            <Grid item xs={4}>
              <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
                <OverflowTip>Location Code</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
                <OverflowTip>Location Name</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
                <OverflowTip>Stock</OverflowTip>
              </Typography>
            </Grid>
          </Grid>

          <RadioGroup
            value={selectedLocationRef.current?.LocationCode || ""}
            onChange={handleLocationChange}
          >
            {locationsRef.current.map((loc, index) => (
              <Grid container spacing={1} key={index} alignItems="center">
                <Grid item xs={1}>
                  <FormControlLabel
                    value={loc.LocationCode} // Ensure value is a string
                    control={<Radio />}
                    label=""
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography noWrap component="div" style={{ paddingLeft: "5px" }}>
                    <OverflowTip>{loc.LocationCode}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography noWrap component="div" style={{ paddingLeft: "5px" }}>
                    <OverflowTip>{loc.LocationName}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography noWrap component="div" style={{ paddingLeft: "5px" }}>
                    <OverflowTip>{loc.Amount}</OverflowTip>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </RadioGroup>

          <Grid container spacing={1} style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "15px" }}>
            <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
              <OverflowTip>Total Stock: {getTotalStock()}</OverflowTip>
            </Typography>
          </Grid>

        </DialogContent>

        <DialogActions style={{ height: '60px', padding: '8px 20px' }}>
          <div>
            <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeChooseLocationsDialog}>
              Cancel
            </CancelButton>
            <SubmitButton
              style={{ marginLeft: "5px" }}
              id="save_btn"
              size="small"
              variant="contained"
              onClick={() => saveLocation()}
              disabled={!selectedLocationRef.current}
            >
              Save
            </SubmitButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddOrder