/* eslint-disable eqeqeq */
import directus from "../../services/directus";

export const handleSyncAllItemsPrice = async (
  setDialogSyncAllOpen,
  setSyncLoading,
  itemsRef,
  selectedCustomerRef,
  setItems,
  orderDate
) => {
  setDialogSyncAllOpen(false);
  setSyncLoading(true);

  const updatedItems = await SyncAllItemsPrice(
    itemsRef.current,
    selectedCustomerRef.current,
    orderDate
  );

  setItems(updatedItems);
  setSyncLoading(false);
};

export const SyncAllItemsPrice = async (items, selectedCustomer, orderDate) => {

  let formattedOrderDate;

  // Check if orderDate is valid
  if (orderDate && !isNaN(new Date(orderDate).getTime())) {
    formattedOrderDate = new Date(orderDate).toISOString().split("T")[0];
  } else {
    formattedOrderDate = new Date().toISOString().split("T")[0]; // Use today's date if invalid
  }

  let updatedItems = items;
  if (items) {
    if (items.length > 0) {
      var items_data = [];

      items.forEach(data => {
        if (data.internal_code != null && data.internal_code !== '' && data.statusValue != 3) {
          items_data.push({
            "Item_No": data.internal_code,
            "Quantity": data.quantity
          })
        }
      })


      if (items_data.length > 0) {
        var customerItemPrices = [];

        if (selectedCustomer) {
          if (selectedCustomer.id) {
            try {
              customerItemPrices = await directus.api.post(`/custom/sales_items/all_prices`, {
                Customer_Id: selectedCustomer.id,
                Items: items_data,
                Order_Date: formattedOrderDate
              });

            } catch { }
          }
        }

        updatedItems = items.map((data) => {
          const internal_code = data.internal_code;
          const quantity = data.quantity ? parseInt(data.quantity) : 0;
          const oldPrice = data.unit_price ?? 0;
          const oldDiscount = data.item_discount;
          let newData = { ...data }; // Create a copy to avoid direct mutation
          let isUpdated = false;
          if (internal_code) {
            const selectedItem = customerItemPrices.find(item => item.Item_No === internal_code && parseInt(item.Quantity) === quantity);
            if (selectedItem) {
              newData.unit_price = selectedItem.Unit_Price;
              newData.item_discount = selectedItem.Line_Discount_Percent;
              isUpdated = true;
            }
          }

          if (!isUpdated) {
            newData.unit_price = data.sales_item ? data.sales_item.price : 0;
            newData.item_discount = 0;
          }

          if (newData.statusValue == 0 && (oldPrice != newData.unit_price || oldDiscount != newData.item_discount)) {
            newData.statusValue = 2
          }

          return newData; // Return updated object
        });
      }
    }
  }

  return updatedItems;
}

export const SyncSingleItemPrice = async (itemData, items, selectedCustomer, orderDate, isUpdated) => {

  let formattedOrderDate;

  // Check if orderDate is valid
  if (orderDate && !isNaN(new Date(orderDate).getTime())) {
    formattedOrderDate = new Date(orderDate).toISOString().split("T")[0];
  } else {
    formattedOrderDate = new Date().toISOString().split("T")[0]; // Use today's date if invalid
  }

  let updatedItems = items;

  var unit_price = itemData.unit_price;
  var item_discount = itemData.item_discount;
  try {
    var item_data = {
      "Item_No": itemData.internal_code,
      "Quantity": itemData.quantity
    }

    var customerItemPrices = await directus.api.post(`/custom/sales_items/all_prices`, {
      Customer_Id: selectedCustomer.id,
      Items: [item_data],
      Order_Date: formattedOrderDate
    });

    if (customerItemPrices) {
      if (customerItemPrices.length > 0) {
        customerItemPrices = customerItemPrices[0];
        if (customerItemPrices.Unit_Price) {
          unit_price = customerItemPrices.Unit_Price ? customerItemPrices.Unit_Price : 0;
          item_discount = customerItemPrices.Line_Discount_Percent ? customerItemPrices.Line_Discount_Percent : 0;
        } else if (itemData.sales_item) {
          if (itemData.sales_item) {
            unit_price = itemData.sales_item.price;
            item_discount = 0;
          }
        }
      }
    }


  } catch { }

  if (unit_price != itemData.unit_price || item_discount != itemData.item_discount) {

    updatedItems = items.map((data) => {
      let newData = { ...data }; // Create a copy to avoid direct mutation


      const quantity = data.quantity ? parseInt(data.quantity) : 0;
      const itemDataQuantity = itemData.quantity ? parseInt(itemData.quantity) : 0;

      if (!isUpdated && (data.internal_code != itemData.internal_code || itemData.sort != data.sort || itemDataQuantity != quantity)) {
        return newData;
      } else if (itemData.id && itemData.id != data.id) {
        return newData;
      }

      newData.unit_price = unit_price;
      newData.item_discount = item_discount;
      if (newData.statusValue == 0) {
        newData.statusValue = 2
      }

      return newData; // Return updated object
    });

  }

  return updatedItems;
}