import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress,
  Switch,
  FormControlLabel,
  TextareaAutosize
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton, RemoveButton, AddAddressButton } from './style.js';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

let page = 1;
const DataSynchronization = (props) => {
  const classes = useStylesEmployees();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useRef(true);
  const [initialized, setInitialized] = useState(false);
  const [, setItems, itemsRef] = useStateRef(null);
  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const [, setDataItem, dataItemRef] = useStateRef({
    id: '',
    destination_table: '',
    integration_source_endpoints: []
  });

  const [, setDefaultDataItem, defaultDataItemRef] = useStateRef({
    id: '',
    destination_table: '',
    integration_source_endpoints: []
  });

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [, setSortField, sortFieldRef] = useStateRef({
    destination_table: { type: 'desc', value: false }
  });
  const [, setSortLoading, sortLoadingRef] = useStateRef(false);
  const [, setDestinationOptions, destinationOptionsRef] = useStateRef(null);

  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setInitialized(true);
      loadIntegrationTables();
      loadData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [initialized]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadIntegrationTables = async () => {
    const filterData = {
      fields: '*',
      limit: pageNumberRef.current,
      page: page,
      meta: 'filter_count',
      sort: 'name',
      filter: { status: 'published' }
    };

    try {
      const ItemsResult = await directus.getItems('integration_tables', filterData);
      if (isMounted.current) {
        const destinationOptions = ItemsResult.data.reduce((acc, item) => {
          acc[item.name] = item.columns;
          return acc;
        }, {});
        setDestinationOptions(destinationOptions);
      }
    } catch (error) {
      enqueueSnackbar("Error loading data: " + error.message, { variant: 'error' });
    }
  };

  const loadData = async () => {
    const filterData = {
      fields: 'id, destination_table, integration_source_endpoints.*, integration_source_endpoints.integration_data_columns.*',
      limit: pageNumberRef.current,
      page: page,
      meta: 'filter_count',
      sort: 'destination_table',
      filter: { status: 'published' }
    };

    try {
      const ItemsResult = await directus.getItems('integration_configuration', filterData);
      if (isMounted.current) {
        setItems(ItemsResult.data);
        setTotalPage(ItemsResult.meta.filter_count);
        setLoadingPagination(false);
        setSortLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Error loading data: " + error.message, { variant: 'error' });
    }
  };

  const handleClickOpen = (item, mode) => {
    setOpen(true);
    setMode(mode);

    if (mode === 'add') {
      setTitle('Add Data Synchronization');
      setText('');
      setDataItem({ id: '', destination_table: '', integration_source_endpoints: [] });
      setDefaultDataItem({ id: '', destination_table: '', integration_source_endpoints: [] });
    } else if (mode === 'edit') {
      setTitle('Edit Data Synchronization');
      setText('');
      // Deep clone the item to ensure complete independence
      const clonedItem = JSON.parse(JSON.stringify(item));
      setDataItem({
        id: clonedItem.id,
        destination_table: clonedItem.destination_table,
        integration_source_endpoints: clonedItem.integration_source_endpoints || []
      });
      setDefaultDataItem({
        id: clonedItem.id,
        destination_table: clonedItem.destination_table,
        integration_source_endpoints: clonedItem.integration_source_endpoints || []
      });
    } else {
      setTitle('Delete Data Synchronization');
      setText('Are you sure?');
      const clonedItem = JSON.parse(JSON.stringify(item));
      setDataItem({
        id: clonedItem.id,
        destination_table: clonedItem.destination_table,
        integration_source_endpoints: clonedItem.integration_source_endpoints || []
      });
      setDefaultDataItem({
        id: clonedItem.id,
        destination_table: clonedItem.destination_table,
        integration_source_endpoints: clonedItem.integration_source_endpoints || []
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDataItem({ id: '', destination_table: '', integration_source_endpoints: [] });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    // Create a new object to avoid mutating defaultDataItemRef
    setDataItem({ ...dataItemRef.current, [name]: value });
  };

  const handleUpdateSourceEndpoints = (index, field, value) => {
    // Deep clone the endpoints array to ensure independence
    const updatedEndpoints = JSON.parse(JSON.stringify(dataItemRef.current.integration_source_endpoints));
    updatedEndpoints[index] = { ...updatedEndpoints[index], [field]: value };
    setDataItem({ ...dataItemRef.current, integration_source_endpoints: updatedEndpoints });
  };

  const handleUpdateDataColumn = (endpointIndex, columnIndex, field, value) => {
    // Deep clone the endpoints array
    const endpoints = JSON.parse(JSON.stringify(dataItemRef.current.integration_source_endpoints));
    const updatedColumns = [...(endpoints[endpointIndex].integration_data_columns || [])];
    updatedColumns[columnIndex] = { ...updatedColumns[columnIndex], [field]: value };
    endpoints[endpointIndex].integration_data_columns = updatedColumns;
    setDataItem({ ...dataItemRef.current, integration_source_endpoints: endpoints });
  };

  const addSourceEndpoint = () => {
    const endpoints = JSON.parse(JSON.stringify(dataItemRef.current.integration_source_endpoints));
    endpoints.push({
      id: '',
      endpoint: '',
      company_code: '',
      v4: false,
      custom: false,
      filters: [],
      integration_data_columns: []
    });
    setDataItem({ ...dataItemRef.current, integration_source_endpoints: endpoints });
  };

  const handleDeleteSourceEndpoints = (index) => {
    const endpoints = JSON.parse(JSON.stringify(dataItemRef.current.integration_source_endpoints)).filter((_, i) => i !== index);
    setDataItem({ ...dataItemRef.current, integration_source_endpoints: endpoints });
  };

  const addDataColumn = (endpointIndex) => {
    const endpoints = JSON.parse(JSON.stringify(dataItemRef.current.integration_source_endpoints));
    const newColumn = { id: '', source_column: '', destination_column: '' };
    endpoints[endpointIndex].integration_data_columns = endpoints[endpointIndex].integration_data_columns || [];
    endpoints[endpointIndex].integration_data_columns.push(newColumn);
    setDataItem({ ...dataItemRef.current, integration_source_endpoints: endpoints });
  };

  const handleDeleteDataColumn = (endpointIndex, columnIndex) => {
    const endpoints = JSON.parse(JSON.stringify(dataItemRef.current.integration_source_endpoints));
    endpoints[endpointIndex].integration_data_columns = endpoints[endpointIndex].integration_data_columns.filter((_, i) => i !== columnIndex);
    setDataItem({ ...dataItemRef.current, integration_source_endpoints: endpoints });
  };

  const SaveData = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const newEndpoints = dataItemRef.current.integration_source_endpoints || [];

        for (const endpoint of newEndpoints) {
          if (endpoint.integration_data_columns) {
            for (const column of endpoint.integration_data_columns) {
              if (column.source_column && !column.destination_column) {
                enqueueSnackbar("Error: A source column is defined without a destination column.", {
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  variant: 'error',
                  autoHideDuration: 3200,
                  action: CloseSnackbarAction
                });
                setLoading(false);
                return;
              }
              if (column.destination_column && !column.source_column) {
                enqueueSnackbar("Error: A destination column is defined without a source column.", {
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  variant: 'error',
                  autoHideDuration: 3200,
                  action: CloseSnackbarAction
                });
                setLoading(false);
                return;
              }
              if (!column.source_column && !column.destination_column) {
                enqueueSnackbar("Error: Both source column and destination column are missing.", {
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  variant: 'error',
                  autoHideDuration: 3200,
                  action: CloseSnackbarAction
                });
                setLoading(false);
                return;
              }
            }
          }
        }

        if (mode === 'edit') {
          await directus.updateItem('integration_configuration', dataItemRef.current.id, {
            destination_table: dataItemRef.current.destination_table,
            status: 'published'
          });

          const oldEndpoints = defaultDataItemRef.current.integration_source_endpoints || [];
          const newEndpointIds = newEndpoints.filter(ep => ep.id).map(ep => ep.id);

          await Promise.all(
            oldEndpoints
              .filter(oldEp => oldEp.id && !newEndpointIds.includes(oldEp.id))
              .map(async (oldEp) => {
                if (oldEp.integration_data_columns && oldEp.integration_data_columns.length > 0) {
                  const columnIds = oldEp.integration_data_columns.map(oldCol => oldCol.id);
                  await directus.deleteItems('integration_data_columns', columnIds);
                }
                return directus.deleteItem('integration_source_endpoints', oldEp.id);
              })
          );

          await Promise.all(
            newEndpoints.map(async (endpoint_value) => {
              if (endpoint_value.id) {
                await directus.updateItem('integration_source_endpoints', endpoint_value.id, {
                  endpoint: endpoint_value.endpoint,
                  v4: endpoint_value.v4,
                  custom: endpoint_value.custom,
                  company_code: endpoint_value.company_code,
                  filters: endpoint_value.filters
                });

                const oldEndpoint = oldEndpoints.find(oldEp => oldEp.id === endpoint_value.id);
                const oldColumns = (oldEndpoint && oldEndpoint.integration_data_columns) || [];
                const newColumns = endpoint_value.integration_data_columns || [];

                if (!newColumns || newColumns.length === 0) {
                  if (oldColumns.length > 0) {
                    const columnIds = oldColumns.map(col => col.id);
                    await directus.deleteItems('integration_data_columns', columnIds);
                  }
                } else {
                  const newColumnIds = newColumns.filter(col => col.id).map(col => col.id);

                  const columnsToDelete = oldColumns.filter(oldCol => oldCol.id && !newColumnIds.includes(oldCol.id));
                  if (columnsToDelete.length > 0) {
                    await directus.deleteItems('integration_data_columns', columnsToDelete.map(col => col.id));
                  }

                  const existingColumns = newColumns.filter(col => col.id);
                  if (existingColumns.length > 0) {
                    await directus.updateItems('integration_data_columns', existingColumns.map(col => ({
                      id: col.id,
                      source_column: col.source_column,
                      destination_column: col.destination_column
                    })));
                  }

                  const newColumnsData = newColumns.filter(col => !col.id);
                  if (newColumnsData.length > 0) {
                    await directus.createItems('integration_data_columns', newColumnsData.map(col => ({
                      integration_source_endpoint: endpoint_value.id,
                      source_column: col.source_column,
                      destination_column: col.destination_column
                    })));
                  }
                }
              } else {
                const endpoint_result = await directus.createItem('integration_source_endpoints', {
                  endpoint: endpoint_value.endpoint,
                  v4: endpoint_value.v4,
                  custom: endpoint_value.custom,
                  integration_configuration: dataItemRef.current.id,
                  company_code: endpoint_value.company_code,
                  filters: endpoint_value.filters
                });

                if (endpoint_result.data && endpoint_value.integration_data_columns && endpoint_value.integration_data_columns.length > 0) {
                  const addColumns = endpoint_value.integration_data_columns.map((column_value) => ({
                    integration_source_endpoint: endpoint_result.data.id,
                    source_column: column_value.source_column,
                    destination_column: column_value.destination_column
                  }));

                  if (addColumns.length > 0) {
                    await directus.createItems('integration_data_columns', addColumns);
                  }
                }
              }
            })
          );

          enqueueSnackbar("Data updated successfully", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });

        } else if (mode === 'add') {
          const newData = {
            destination_table: dataItemRef.current.destination_table,
            status: 'published'
          };
          const integration_result = await directus.createItem('integration_configuration', newData);

          if (integration_result.data) {
            await Promise.all(
              newEndpoints.map(async (endpoint_value) => {
                const endpoint_result = await directus.createItem('integration_source_endpoints', {
                  endpoint: endpoint_value.endpoint,
                  v4: endpoint_value.v4,
                  custom: endpoint_value.custom,
                  integration_configuration: integration_result.data.id,
                  company_code: endpoint_value.company_code,
                  filters: endpoint_value.filters
                });

                if (endpoint_result.data && endpoint_value.integration_data_columns && endpoint_value.integration_data_columns.length > 0) {
                  const addColumns = endpoint_value.integration_data_columns.map((column_value) => ({
                    integration_source_endpoint: endpoint_result.data.id,
                    source_column: column_value.source_column,
                    destination_column: column_value.destination_column
                  }));

                  if (addColumns.length > 0) {
                    await directus.createItems('integration_data_columns', addColumns);
                  }
                }
              })
            );
          }

          enqueueSnackbar("Data added successfully", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });

        } else {
          await directus.updateItem('integration_configuration', dataItemRef.current.id, { status: "deleted" });

          enqueueSnackbar("Data deleted successfully", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
        }

        loadData();
        handleClose();
      } catch (error) {
        enqueueSnackbar("Error: " + error.message, { variant: 'error' });
      }
      setLoading(false);
    }
  };

  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadData();
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadData();
  };

  const sortData = (val) => {
    setSortLoading(true);
    let sort_field_data = {
      destination_table: { type: 'desc', value: false }
    };

    let sort_data = sortFieldRef.current[val];
    if (sort_data) {
      let value = sort_data.value ? !sort_data.value : true;
      let sort_type = sort_data.type === 'desc' ? 'asc' : 'desc';
      sort_field_data[val] = { type: sort_type, value: value };
      localStorage.setItem("sort_data", JSON.stringify({ accounting_systems: sort_field_data }));
      setSortField(sort_field_data);
    }
    loadData();
  };

  return (
    <div>
      {itemsRef.current ? (
        <div>
          <List component="nav" className={classes.root} dense style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={classes.headerSort} onClick={() => sortData("destination_table")}>
                        <Typography noWrap variant="h5">Destination Table</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['destination_table'].value && (
                            sortFieldRef.current['destination_table'].type === "asc" ?
                              <ArrowForwardIosRoundedIcon className={classes.ascSort} /> :
                              <ArrowBackIosRoundedIcon className={classes.descSort} />
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.headerSort}>
                        <Typography noWrap variant="h5">Source Endpoints</Typography>
                      </div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                {props.config_settings && (
                  <AddButton size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                    <AddIcon />
                  </AddButton>
                )}
              </Grid>
            </ListItem>
          </List>
          {sortLoadingRef.current && (
            <Grid item xs={12}>
              <LinearProgress className={classes.linearProgress} style={{ margin: "0 7px" }} />
            </Grid>
          )}
          <List component="nav" className={classes.root} dense style={{ marginBottom: '20px' }}>
            {itemsRef.current.map((item, index) => (
              <div key={index}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="h6">{item.destination_table || ''}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6">
                            {item.integration_source_endpoints ? item.integration_source_endpoints.length : 0} item
                            {item.integration_source_endpoints && item.integration_source_endpoints.length > 1 ? 's' : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                  {props.config_settings ? (
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <IconButton onClick={() => handleClickOpen(item, 'edit')}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleClickOpen(item, 'delete')}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={2} style={{ textAlign: 'right' }}></Grid>
                  )}
                </ListItem>
                {itemsRef.current.length - 1 > index && <Divider />}
              </div>
            ))}
            <Dialog open={open} fullWidth onClose={handleClose}>
              <DialogTitle disableTypography>
                <Typography variant="h5">{title}</Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {mode !== 'delete' ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          select
                          label="Destination Table"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          name="destination_table"
                          value={dataItemRef.current.destination_table}
                          onChange={handleChangeInput}
                        >
                          {Object.keys(destinationOptionsRef.current).map((table) => (
                            <MenuItem key={table} value={table}>
                              {table}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: "15px" }}><div className={classes.header}><span>Source Endpoints</span></div></Grid>

                      {dataItemRef.current.integration_source_endpoints &&
                        dataItemRef.current.integration_source_endpoints.map((endpoint, endpointIndex) => {
                          return (
                            <Grid container spacing={2} key={endpoint.id} style={{ marginTop: endpointIndex === 0 ? '0px' : '30px', border: '1px solid #ccc', padding: '10px', borderRadius: '4px', marginLeft: 0, marginRight: 0 }}>
                              <Grid item xs={6}>
                                <TextField
                                  label="Endpoint"
                                  variant="outlined"
                                  fullWidth
                                  value={endpoint.endpoint}
                                  onChange={(e) => handleUpdateSourceEndpoints(endpointIndex, "endpoint", e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="Company Code"
                                  variant="outlined"
                                  fullWidth
                                  value={endpoint.company_code}
                                  onChange={(e) => handleUpdateSourceEndpoints(endpointIndex, "company_code", e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={endpoint.custom}
                                      onChange={(e) => handleUpdateSourceEndpoints(endpointIndex, "custom", e.target.checked)}
                                    />
                                  }
                                  label="Custom"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={endpoint.v4}
                                      onChange={(e) => handleUpdateSourceEndpoints(endpointIndex, "v4", e.target.checked)}
                                    />
                                  }
                                  label="V4"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">Filters</Typography>
                                <TextareaAutosize
                                  minRows={2}
                                  placeholder="Enter filters (one per line)"
                                  value={endpoint.filters.join('\n')}
                                  onChange={(e) =>
                                    handleUpdateSourceEndpoints(endpointIndex, "filters", e.target.value.split('\n'))
                                  }
                                  style={{ width: '100%', padding: '10px', borderRadius: '4px', borderColor: '#ccc' }}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: '8px' }}>
                                <Typography variant="h6">Data Column Mapping</Typography>
                              </Grid>
                              {endpoint.integration_data_columns &&
                                endpoint.integration_data_columns.map((column, columnIndex) => (
                                  <Grid container key={columnIndex} style={{ marginTop: '15px' }}>
                                    <Grid item xs={11}>
                                      <Grid container spacing={2} style={{ marginLeft: 0, marginRight: 0 }}>
                                        <Grid item xs={6}>
                                          <TextField
                                            label="Source Column"
                                            variant="outlined"
                                            fullWidth
                                            value={column.source_column}
                                            onChange={(e) =>
                                              handleUpdateDataColumn(endpointIndex, columnIndex, "source_column", e.target.value)
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <TextField
                                            select
                                            label="Destination Column"
                                            variant="outlined"
                                            fullWidth
                                            value={column.destination_column}
                                            onChange={(e) =>
                                              handleUpdateDataColumn(endpointIndex, columnIndex, "destination_column", e.target.value)
                                            }
                                          >
                                            {(destinationOptionsRef.current[dataItemRef.current.destination_table] || []).map((option) => (
                                              <MenuItem key={option} value={option}>
                                                {option}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <IconButton onClick={() => handleDeleteDataColumn(endpointIndex, columnIndex)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}

                              <div style={{ margin: '20px 0px 10px 8px' }}>
                                <AddAddressButton
                                  data-testid="add_address_btn"
                                  variant="outlined"
                                  onClick={() => addDataColumn(endpointIndex)}
                                  startIcon={<img src='/static/images/icons/add-item.svg' alt='add icon' style={{ width: "15px", marginRight: "-3px" }} />}
                                  style={{ fontSize: "11px" }}
                                >
                                  Add New Column
                                </AddAddressButton>
                              </div>
                              <Grid item xs={12} style={{ textAlign: 'right', marginTop: '15px' }}>
                                <RemoveButton
                                  variant="outlined"
                                  onClick={() => handleDeleteSourceEndpoints(endpointIndex)}
                                >
                                  Delete Endpoint
                                </RemoveButton>
                              </Grid>
                            </Grid>
                          );
                        })}

                      {mode !== 'delete' ?
                        <div style={{ margin: '30px 0px 10px 8px' }}>
                          <AddAddressButton
                            data-testid="add_address_btn"
                            variant="outlined"
                            onClick={() => addSourceEndpoint()}
                            startIcon={<img src='/static/images/icons/add-item.svg' alt='add icon' />}
                          >
                            Add Endpoint
                          </AddAddressButton>
                        </div>
                        : ''}
                    </>
                  ) : (
                    <Typography>{text}</Typography>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions style={{ height: "60px" }}>
                {loading ? (
                  <CircularProgress className={classes.circular} style={{ marginRight: "1px" }} />
                ) : (
                  <div>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                      style={{ marginRight: '5px' }}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id={`${mode === 'delete' ? 'delete' : 'save'}_btn`}
                      size="small"
                      variant="contained"
                      onClick={() => SaveData()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                  </div>
                )}
              </DialogActions>
            </Dialog>
          </List>
        </div>
      ) : (
        <CircularProgress className={classes.circular} />
      )}

      {itemsRef.current && (
        <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'SF Pro' }}>
          <span style={{ paddingRight: '20px' }}>Rows per page:</span>
          <span style={{ paddingRight: '20px' }}>
            <TextField select value={pageNumber} onChange={handleChange}>
              {[{ value: 50, label: 50 }, { value: 100, label: 100 }, { value: 200, label: 200 }].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>
          <span style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1} - {pageNumberRef.current * page > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>
          <Button
            variant="outlined"
            size="small"
            disabled={page === 1 || loadingPagination}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
            className={classes.buttonRight}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
      )}
    </div>
  );
};

export default DataSynchronization;