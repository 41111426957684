import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { filterOption } from 'src/components/ReusableFunction';

const GooglePlacesAddressAutocomplete = ({
  inputAddressValue,
  setInputAddressValue,
  onChangeAddress,
  deliveryAddressOptionSelected,
}) => {
  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      autocompletionRequest={{
        componentRestrictions: { country: "au" },
        types: ['address'],
        fields: ["address_components", "geometry", "icon", "name"]
      }}
      selectProps={{
        inputValue: inputAddressValue,
        className: `select-address-autocomplete address`,
        placeholder: 'Search address...',
        blurInputOnSelect: true,
        onInputChange: (value, { action }) => {
          if (action === "input-change") {
            setInputAddressValue(value);
          }
        },
        onChange: (data) => {
          onChangeAddress(data);
        },
        styles: {
          option: (provided, { isFocused, isSelected }) => ({
            ...provided,
            background: isSelected ? "#d5f2be !important" : isFocused ? "#d5f2be !important" : "white"
          })
        },
        value: deliveryAddressOptionSelected,
        controlShouldRenderValue: true,
        isClearable: true,
        filterOption: filterOption
      }}
    />
  );
};

export default GooglePlacesAddressAutocomplete;
