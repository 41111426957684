import React from 'react';
import { Autocomplete, Box } from '@mui/material';
import { MenuItem, TextField, Grid } from "@material-ui/core";
import { filterOptionsItemNumberAndDescription } from '../style';
import { formatNumber } from 'src/components/ReusableFunction';

const SalesItemCodeDescriptionAutocomplete = ({
  index,
  itemsOptionsRef,
  classes,
  dataItem,
  handleChangeSalesItem,
  name
}) => {
  return (
    <Autocomplete
      id={`order_item_${name}_${index}`}
      options={itemsOptionsRef.current}
      className={classes.autocompleteEditable}
      filterOptions={filterOptionsItemNumberAndDescription}
      disableClearable
      classes={{
        paper: classes.paperstyle
      }}
      popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
      getOptionLabel={(option) => name === "code" ? option.item_number ?? "" : option.description ?? ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      data-testid={`order-item-${name}`}
      value={dataItem.sales_item ?? ''}
      sx={{
        '& .MuiAutocomplete-input': {
          textAlign: 'left',
          color: 'black',
        },
      }}
      onChange={(event, newValue) => {
        handleChangeSalesItem(newValue, dataItem);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" />
      )}
      renderOption={(props, option) => {
        let isSelected = false;
        if (dataItem.sales_item) {
          isSelected = option.id === dataItem.sales_item.id;
        }

        return (
          <MenuItem
            {...props}
            selected={isSelected}
            key={`${name}-` + option.id}
            className={isSelected ? classes.selectedMenuItem : classes.menuItem}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Grid container spacing={0} justifyContent="space-around">
                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                  {option.item_number}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridStyle}>
                  {option.description}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                  {isNaN(option.price) ? 0 : '$' + formatNumber((Math.round(option.price * 100) / 100).toFixed(2))}
                </Grid>
              </Grid>
            </Box>
          </MenuItem>
        );
      }}
    />
  );
};

export default SalesItemCodeDescriptionAutocomplete;