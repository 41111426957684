import React from 'react';
import { Autocomplete, Box } from '@mui/material';
import { MenuItem, TextField } from "@material-ui/core";

const DeliveryAddressAutocomplete = ({
  deliveryAddressOptions,
  classes,
  deliveryAddress,
  setDeliveryAddress,
  formattingDeliveryAddress,
  selectedOrder,
  setSelectedDeliveryAddress,
  setDeliveryAddressOptionId,
  setDeliveryAddressOptionSelected,
  setInputAddressValue
}) => {
  return (
    <Autocomplete
      id="delivery-address-options"
      data-testid="delivery-address-options"
      options={deliveryAddressOptions}
      className={classes.autocompleteEditable2}
      disableClearable
      popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
      getOptionLabel={(option) => option.lable || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={deliveryAddress ?? ''}
      sx={{
        height: '49px !important',
        '& .MuiAutocomplete-input': {
          textAlign: 'right',
          color: 'rgba(60, 60, 67, 0.6)',
        },
        '& .MuiOutlinedInput-root': {
          height: '49px !important',
        }
      }}
      onChange={(event, newValue) => {
        setDeliveryAddress(newValue);
        formattingDeliveryAddress(newValue, selectedOrder, setSelectedDeliveryAddress);
        setDeliveryAddressOptionId(newValue.id);
        setDeliveryAddressOptionSelected('');
        setInputAddressValue('');
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" />
      )}
      renderOption={(props, option) => {
        let isSelected = false;
        if (deliveryAddress) {
          isSelected = option.id === deliveryAddress.id;
        }

        return (
          <MenuItem
            {...props}
            selected={isSelected}
            className={isSelected ? classes.selectedMenuItem : classes.menuItem}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word', textOverflow: 'ellipsis', whiteSpace: 'normal', paddingRight: '5px' }}>
                {option.lable}
              </span>
            </Box>
          </MenuItem>
        );
      }}
    />
  );
};

export default DeliveryAddressAutocomplete;