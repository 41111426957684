/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import directus from "../../services/directus";
import moment from "moment";
import linq from 'linq';
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import ErrorMessage from 'src/components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import {
  formatNumber,
  OrderDetailLoadingView,
  customerFields
} from 'src/components/ReusableFunction';
import { getCache, setCache } from 'src/utils/KeyValueCache';
import { OverflowTip } from "src/components/OverflowTip";
import { IconButton, Divider } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Autocomplete from '@mui/material/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  getStatusIconPath, theme, AddItemButton, CustomButton, CustomButton2, SubmitButton,
  CancelButton,
} from 'src/components/ReusableStyle';
import {
  useStyles,
  NumberFormatCustom,
  NumberFormatCustomPercent,
} from './style.js';
import {
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Tooltip,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import OrderItemsNotDraftView from 'src/views/SalesOrders/components/OrderItemsNotDraftView';
import ButtonsView from 'src/views/SalesOrders/components/ButtonsView';
import AddOrder from './AddOrder';
import DescriptionIcon from '@mui/icons-material/Description';
import SyncingProcessWithText from 'src/components/SyncingProcessWithText';
import SyncIcon from '@mui/icons-material/Sync';
import { SyncSingleItemPrice } from './SyncItemPrices';
import InfoIcon from '@material-ui/icons/Info';
import {
  calculateSubTotal, calculateTotal, calculateItemTotal,
  loadItems, loadCustomer,
  getDeliveryAddress, getConfigurationSettings,
  handleChangeDeliveryAddressOption,
  handleChangeAddress,
  formattingDeliveryAddress
} from 'src/views/SalesOrders/SalesOrderFunction';
import { useSyncDialogs } from 'src/views/SalesOrders/components/SyncPricesDialog';
import CustomerAutocomplete from 'src/views/SalesOrders/components/CustomerAutocomplete';
import SalesItemCodeDescriptionAutocomplete from 'src/views/SalesOrders/components/SalesItemCodeDescriptionAutocomplete';
import DeliveryAddressAutocomplete from 'src/views/SalesOrders/components/DeliveryAddressAutocomplete';
import GooglePlacesAddressAutocomplete from 'src/views/SalesOrders/components/GooglePlacesAddressAutocomplete';
import ApartmentIcon from '@mui/icons-material/Apartment';

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search("interax") !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "#929292"
  }
})(Tooltip);

function SelectedOrderView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [selectedOrder, setSelectedOrder, selectedOrderRef] = useStateRef({ statusIconPath: getStatusIconPath('draft'), status: 'draft' });
  const [orderLoading, setOrderLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [add, setAdd] = useState(false);
  const [openChangeLog, setOpenChangeLog] = useState(false);
  const [showTotalDetails, setShowTotalDetails] = useState(false);
  const [logData, setLogData] = useState([]);
  const [logDataLoading, setLogDataLoading] = useState(false);
  const [orderEventType, setOrderEventType] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [, setIsCustomEmail, isCustomEmailRef] = useStateRef(false);
  const [email, setEmail] = useState('');
  const [, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);
  const [customEmailLoading, setCustomEmailLoading] = useState(false);
  const [, setDataCustomEmail, dataCustomEmailRef] = useStateRef({
    send_to_email: null,
    cc_email: null
  });

  const [itemsLoading, setItemsLoading] = useState(false);
  const [, setItemsOptions, itemsOptionsRef] = useStateRef([]);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [, setDeliveryAddressOptionId, deliveryAddressOptionIdRef] = useStateRef(0);
  const [deliveryAddressOptionSelected, setDeliveryAddressOptionSelected] = useState([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState([{ id: -1, address_name: 'Custom', lable: 'Custom' }]);

  const [, setInputAddressValue, inputAddressValueRef] = useStateRef('');
  const [, setSelectedDeliveryAddress, selectedDeliveryAddressRef] = useStateRef({
    AddressLine1: '',
    AddressLine2: '',
    AddressName: '',
    AddressCity: '',
    AddressState: '',
    AddressPostcode: '',
  });

  const [, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [, setSubTotal, subTotalRef] = useStateRef(0);
  const [, setSubTotalIncludingTax, subTotalIncludingTaxRef] = useStateRef(0);
  const [, setTotalTax, totalTaxRef] = useStateRef(0);
  const [, setOrderDiscount, orderDiscountRef] = useStateRef(0);
  const [, setOrderDiscountPercent, orderDiscountPercentRef] = useStateRef(0);
  const [, setShipping, shippingRef] = useStateRef(0);

  const [items, setItems, itemsRef] = useStateRef(null);
  const [itemCheck, setItemCheck] = useState(false);
  const [, setItemCount, itemCountRef] = useStateRef(false);
  const [maxSort, setMaxSort] = useState(false);

  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  const [, setCustomerOptions, customerOptionsRef] = useStateRef([]);

  const [selectedDeliveryDate, handleDeliveryDateChange] = useState(new Date());
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [notes, setNotes] = useState(null);
  const [loadingSaveNotes, setLoadingSaveNotes] = useState(false);
  const isMounted = useRef(true);
  const [, setSyncLoading, syncLoadingRef] = useStateRef(false);

  const [typingTimeout, setTypingTimeout] = useState(null);

  const [, setSelectedSyncItem, selectedSyncItemRef] = useStateRef(null);
  const [soPrefix, setSoPrefix] = useState('');

  const [, setEnableItemDiscountPercent, isEnableItemDiscountPercentRef] = useStateRef(false);
  const [, setMaxOrderItemDiscountPercent, maxOrderItemDiscountPercentRef] = useStateRef(0);
  const [, setMaxOrderDiscount, maxOrderDiscountRef] = useStateRef(0);

  const { SyncDialogs, setDialogSyncAllOpen, setDialogSyncItemOpen } =
    useSyncDialogs(itemsRef, selectedCustomerRef, setItems, setSyncLoading, selectedSyncItemRef, selectedOrderRef.current ? selectedOrderRef.current.order_date : null);

  const [, setOpenChooseLocationDialog, openChooseLocationDialogRef] = useStateRef(false);
  const [, setLocations, locationsRef] = useStateRef([]);
  const [, setSelectedLocation, selectedLocationRef] = useStateRef("");
  const [, setLoadingItemStockLocation, loadingItemStockLocationRef] = useStateRef(false);
  const [, setSelectedItem, selectedItemRef] = useStateRef("");


  useEffect(() => {
    isMounted.current = true; // component mount
    if (!initialized) {
      setInitialized(true);
      loadOrderSelected(false, true);
      loadOrderEventType();
      loadCustomer(
        customerFields,
        isMounted,
        setCustomerOptions,
        enqueueSnackbar,
      );
      loadItems(
        setItemsLoading,
        setItemsOptions,
        enqueueSnackbar
      );
      getConfigurationSettings(
        isMounted,
        setShowTotalDetails,
        setEnableItemDiscountPercent,
        setMaxOrderItemDiscountPercent,
        setMaxOrderDiscount,
        setSoPrefix
      );
    }

    return () => {
      isMounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const changeSelectedOrder = async () => {
      setItemCheck(true);
      setOrderLoading(true);
      await loadOrderSelected();
    };

    const listener1 = EventEmitter.addListener("selected_sales_order", changeSelectedOrder);

    const add = async eventData => {
      navigate(`/sales-orders/new`);
      await loadOrderSelected();
    };
    const listener2 = EventEmitter.addListener("addOrder", add);

    const navigationChanges = async () => {
      let pathname = window.location.pathname;
      if (pathname.includes('sales-orders')) {
        await loadOrderSelected();
      }
    };

    const listener3 = EventEmitter.addListener('navigation_changes', navigationChanges);

    return () => {
      listener1.remove();
      listener2.remove();
      listener3.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadOrderSelected = async (loadStatus = true, firstLoading = false) => {
    if (loadStatus) {
      setOrderLoading(true);
    }

    const selectedOrderId = window.location.pathname.split('/').pop();
    if (selectedOrderId !== 'new') {
      const params_filter = [
        "employee.id",
        "employee.owner",
        "employee.position_level",
        "employee.job_title",
        "employee.mobile_number",
        "employee.code",
        "employee.type",
        "employee.email",
        "employee.user.*",
        "farm_code.id",
        "farm_code.code",
        "farm_code.name",
        "farm_code.email",
        "farm_code.status",
        "farm_code.legal_name",
        "farm_code.billing_address_line_1",
        "farm_code.billing_address_line_2",
        "farm_code.billing_city",
        "farm_code.billing_state",
        "farm_code.billing_post_code",
        "farm_code.additional_address.*",
        "modified_on",
        "modified_by.*",
        "files.*.*",
        "sales_order_items.sales_order_item.description",
        "sales_order_items.sales_order_item.id",
        "sales_order_items.sales_order_item.internal_code",
        "sales_order_items.sales_order_item.quantity",
        "sales_order_items.sales_order_item.sort",
        "sales_order_items.sales_order_item.unit_price",
        "sales_order_items.sales_order_item.item_discount",
        "sales_order_items.sales_order_item.location_code",
        "sales_order_items.sales_order_item.sales_item.*",
        "sales_order_items.id",
        "id",
        "status",
        "order_date",
        "cc_email",
        "notes",
        "order_number",
        "farm_code",
        "additional_notes",
        "due_date",
        "delivery_instructions",
        "delivery_address_option",
        "delivery_address_name",
        "delivery_address_line_1",
        "delivery_address_line_2",
        "delivery_address_city",
        "delivery_address_state",
        "delivery_address_postcode",
        "send_to_email",
        "order_discount",
        "shipping",
        "owner.id",
      ];

      // get the selected order data
      const orderSelected = await directus.api.post(`/custom/sales_orders?fields=${params_filter}`, {
        filter: {
          "sales_orders.id": selectedOrderId
        }
      });

      if (orderSelected.data.length > 0) {
        let orderSelectedData = orderSelected.data[0];

        let totalOrder = 0;

        if (orderSelectedData) {
          var customer_name = "";

          if (orderSelectedData.farm_code !== null) {
            if (orderSelectedData.farm_code.name) {
              customer_name = orderSelectedData.farm_code.name;
            }
          }

          orderSelectedData.customer_name = customer_name;

          orderSelectedData.sales_order_items.forEach(data => {
            if (data.sales_order_item !== null) {
              totalOrder = totalOrder + data.sales_order_item.unit_price * data.sales_order_item.quantity;
            }
          });

          orderSelectedData.total_order = totalOrder;

          orderSelectedData.statusIconPath = getStatusIconPath(orderSelectedData.status);
        }

        if (isMounted.current) {
          setSelectedOrder(orderSelectedData);

          if (orderSelectedData.status === 'draft') {
            setIsEditable(true);
          } else {
            setIsEditable(false);
          }

          setDeliveryAddressOptionId(orderSelectedData.delivery_address_option ?? 0);

          if (orderSelectedData.delivery_address_option == -1) {
            setInputAddressValue(
              `${orderSelectedData.delivery_address_line_1 ? orderSelectedData.delivery_address_line_1 + ', ' : ''}` +
              `${orderSelectedData.delivery_address_line_2 ? orderSelectedData.delivery_address_line_2 + ', ' : ''}` +
              `${orderSelectedData.delivery_address_city ? orderSelectedData.delivery_address_city + ' ' : ''}` +
              `${orderSelectedData.delivery_address_state ? orderSelectedData.delivery_address_state + ' ' : ''}` +
              `${orderSelectedData.delivery_address_postcode ? orderSelectedData.delivery_address_postcode + ' ' : ''}`
            );
            setDeliveryAddressOptionSelected('');
          } else {
            setInputAddressValue(null);
          }

          changeDeliveryAddressOption(orderSelectedData.farm_code);

          setSelectedCustomer(orderSelectedData.farm_code);
          setAdd(false);
          setTotalOrderItem(totalOrder);
          loadSalesOrderItems();
          setEmail(orderSelectedData.cc_email);
          setDeliveryInstructions(orderSelectedData.delivery_instructions);
          handleDeliveryDateChange(orderSelectedData.due_date ? moment(orderSelectedData.due_date).format() : Date.now());
          setNotes(orderSelectedData.notes);
          setOrderDiscount(orderSelectedData.order_discount);
          setShipping(orderSelectedData.shipping);
        }
      }
    } else {
      if (isMounted.current) {
        setAdd(true);
        setIsEditable(true);
        setDeliveryAddress(null);
        setTotalOrderItem(0);
        setEmail('');
        setDeliveryInstructions(null);
        setNotes('');
        handleDeliveryDateChange(new Date())
        setSelectedCustomer(null);
        setSelectedOrder({ statusIconPath: getStatusIconPath('draft'), status: 'draft' });
        setItems(null);
        setOrderDiscount(0);
        setShipping(0);
      }
    }

    if (isMounted.current) {
      countTotal();
      setOrderLoading(false);
    }
  }

  const getAllItemsStockLocations = async (internal_code) => {

    try {
      setLoadingItemStockLocation(true);
      setSelectedItem("all");
      const response = await directus.api.post(`/custom/sales_items/locations`, {
        Items: internal_code,
      });
      // Merge stock locations with existing items
      const updatedItems = mergeAllItemsWithStockLocations(response);

      setItems(updatedItems);

    } catch (error) {
      console.error("Error fetching locations:", error);
    }

    setLoadingItemStockLocation(false);
  }

  // Separate function to merge stock locations
  const mergeAllItemsWithStockLocations = (codeLocationList) => {
    return itemsRef.current.map(existingItem => {
      const stockLocations = codeLocationList[existingItem.internal_code] || [];

      var selectedLocation = null;

      if (existingItem.location_code) {
        selectedLocation = stockLocations.find(location => location.LocationCode == existingItem.location_code) || null;
      }

      return {
        ...existingItem,
        stock_locations: stockLocations,
        selected_location: selectedLocation
      };
    });
  };

  const loadSalesOrderItems = () => {
    let itemData = [];

    let internal_code = [];
    if (selectedOrderRef.current && selectedOrderRef.current.sales_order_items.length > 0) {
      //eslint-disable-next-line array-callback-return
      selectedOrderRef.current.sales_order_items.map((data, index) => {
        itemData[index] = { ...data.sales_order_item, statusValue: 0, count: index, orders_items_id: data.idn };
        internal_code.push(data.sales_order_item.internal_code);
      });

      setItems(itemData);
      setItemCount(itemData.length);
      var maxSort = linq.from(selectedOrderRef.current.sales_order_items).maxBy(s => s.sales_order_item.sort);
      if (maxSort) {
        setMaxSort(maxSort.sales_order_item.sort);
      }
    } else {
      setItems(null);
      setItemCount(0);
      setSubTotal(0);
      setTotalTax(0);
    }

    addItemsCheck(itemCountRef.current);

    if (internal_code.length > 0 && selectedOrder && selectedOrder.status === "draft") {
      getAllItemsStockLocations(internal_code);
    }
  };

  const loadOrderEventType = async () => {
    let company = JSON.parse(window.localStorage.getItem("company"));
    let token = JSON.parse(window.localStorage.getItem("directus_data"));

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token
      }
    };

    var sales_order_event_type = getCache("sales_order_event_type_options_choices");

    if (!sales_order_event_type) {
      fetch(`${react_app_url}${company}/fields/sales_order_event/type`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.data && isMounted.current) {
            setOrderEventType(result.data.options.choices);
            setCache("sales_order_event_type_options_choices", result.data.options.choices);
          }
        });
    } else {
      if (isMounted.current) {
        setOrderEventType(sales_order_event_type);
      }
    }
  };

  const addItemsCheck = itemCount => {
    var item_check = true;

    //eslint-disable-next-line array-callback-return
    itemsRef.current && itemsRef.current.map(data => {
      if (data.statusValue !== 3) {
        if (itemCount === 0) {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            item_check = false;
          }
        } else {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            item_check = false;
          }
        }
      }
    });

    setItemCheck(item_check);
  };

  const countTotal = (skipDiscountPercent = false) => {
    calculateTotal(
      itemsRef.current,
      orderDiscountRef.current,
      shippingRef.current,
      setSubTotalIncludingTax,
      setSubTotal,
      setTotalTax,
      setTotalOrderItem,
      setOrderDiscount,
      setOrderDiscountPercent,
      skipDiscountPercent
    );
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    } else if (name === 'notes') {
      setNotes(value);
    } else if (name === 'order_discount') {
      const new_value = value ? parseFloat(value) : 0;
      setOrderDiscount(new_value);
      countTotal();
    } else if (name === 'order_discount_percent') {
      if (value === "") {
        setOrderDiscountPercent("");
        return;
      }

      // Allow numbers with up to 3 decimal places
      if (!/^\d*\.?\d{0,3}$/.test(value)) {
        return; // Prevent invalid input
      }


      let sub_total = calculateSubTotal(itemsRef.current);

      var new_value = value ? value : 0;

      if (new_value <= 100) {
        setOrderDiscountPercent(new_value); // Store as raw number

        if (new_value) {
          var discount = (new_value / 100) * sub_total;

          setOrderDiscount(parseFloat(discount).toFixed(2));
        } else {
          setOrderDiscount(0);
        }

        countTotal(true);
      }
    } else if (name === 'shipping') {
      setShipping(value);
      countTotal();
    }
  };

  const addItems = itemVar => {
    let data = [];
    data.id = uuidv4();
    if (itemVar === null) {
      data.statusValue = 1;
      data.count = 0;
      setItems([data]);
      setItemCount(data.count);
    } else {
      data.statusValue = 1;
      data.count = (itemsRef.current.length > 0 ? itemsRef.current[itemsRef.current.length - 1].count : 0) + 1;
      data.sort = maxSort + 1;
      setItems([...itemsRef.current, data]);
      setItemCount(data.count);
    }

    addItemsCheck(itemCountRef.current);
  };

  const deleteItems = itemData => {
    if (itemData.statusValue === 1) {
      setItems(itemsRef.current.filter(data => data.count !== itemData.count));
    } else {
      setItems(itemsRef.current.map(data => (data.count === itemData.count ? { ...data, statusValue: 3 } : data)));
    }

    deleteItemsCheck(itemData);
    addItemsCheck(itemCountRef.current);
    countTotal();
  };

  const deleteItemsCheck = itemData => {
    let items = [];
    items = itemsRef.current.filter(data => data.count !== itemData.count);

    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.quantity < 0 ||
      items.description == undefined
    ) {
      setItemCheck(false);
    }
  };

  const changeItems = (e, oldData, newData) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }

    if (name == "item_discount" && !isEnableItemDiscountPercentRef.current) return false;

    setItems(
      items.map(dataList =>
        dataList.id === oldData.id
          ? dataList.statusValue === 1
            ? { ...oldData, statusValue: 1, [name]: value }
            : { ...oldData, statusValue: 2, [name]: value }
          : dataList
      )
    );

    countTotal();
    addItemsCheck(itemCountRef.current);

    // Clear existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to delay the function execution
    if (name === "quantity") {
      setTypingTimeout(setTimeout(() => {
        if (oldData && !Array.isArray(oldData) && oldData.statusValue == 1) {
          oldData["quantity"] = value;
          syncItem(oldData);
        }
      }, 1000));
    }

  };

  const handleOpenChangeLog = async () => {
    setOpenChangeLog(true);
    setLogDataLoading(true);
    if (selectedOrderRef.current) {
      var OrderEvent = await directus.getItems('sales_order_event', {
        fields: '*.*',
        filter: {
          sales_order: selectedOrderRef.current.id
        },
        sort: '-id'
      });
      setLogData(OrderEvent.data);
    }
    setLogDataLoading(false);
  };

  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  /* eslint-disable array-callback-return*/
  const editSubmit = async (id, type, send_customer = false, custom_send = false) => {
    if (type == "sent") {
      var show_limit_error_message = false;

      var error_message = "";

      if (maxOrderItemDiscountPercentRef.current && isEnableItemDiscountPercentRef.current) {
        itemsRef.current.forEach((data) => {
          if (data.item_discount && data.statusValue !== 3 && maxOrderItemDiscountPercentRef.current < data.item_discount) {
            show_limit_error_message = true;
            error_message += `- Item discount for ${data.internal_code ? "'" + data.internal_code + "'" : "an item"} exceeds the limit of `;
            error_message += formatNumber((maxOrderItemDiscountPercentRef.current).toFixed(2)) + "%.\n";
          }
        });
      }

      if (maxOrderDiscountRef.current && maxOrderDiscountRef.current < orderDiscountRef.current) {
        show_limit_error_message = true;
        error_message += `- Order discount cannot exceed $${formatNumber(maxOrderDiscountRef.current.toFixed(2))}.\n`;
      }

      if (show_limit_error_message) {
        error_message += "Please adjust your discount accordingly.";
        window.alert(error_message);
        return false;
      }

    }

    setLoadingSubmit(true);

    let addItems = [];
    let editItems = [];
    let delItems = [];
    let delItems2 = [];

    var sort = maxSort + 1;
    if (items) {
      items.map((data, index) => {
        if (data.statusValue === 3) {
          delItems = [...delItems, data.orders_items_id];
          delItems2 = [...delItems2, data.id];
        }
        var location_code = "";

        if (data.selected_location) {
          if (data.selected_location.LocationCode) {
            location_code = data.selected_location.LocationCode;
          }
        }

        if (data.statusValue === 2) {
          if (data.sort !== null && data.sort !== undefined && data.sort !== '') {
            editItems = [
              ...editItems,
              {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                cc_email: data.cc_email,
                note: data.note,
                sort: data.sort,
                internal_code: data.internal_code,
                item_discount: data.item_discount ?? 0,
                sales_item: data.sales_item ? data.sales_item.id : null,
                location_code: location_code
              }
            ];
          } else {
            editItems = [
              ...editItems,
              {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                cc_email: data.cc_email,
                note: data.note,
                internal_code: data.internal_code,
                item_discount: data.item_discount ?? 0,
                sales_item: data.sales_item ? data.sales_item.id : null,
                location_code: location_code
              }
            ];
          }
        }

        if (data.statusValue === 1 && data.description) {
          var defaultSort = sort;
          if (data.sort !== null && data.sort !== undefined) {
            defaultSort = data.sort;
          }
          addItems = [
            ...addItems,
            {
              sales_order: { id: id },
              sales_order_item: {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                sort: defaultSort,
                internal_code: data.internal_code,
                item_discount: data.item_discount ?? 0,
                sales_item: data.sales_item ? data.sales_item.id : null,
                location_code: location_code
              }
            }
          ];
          sort++;
        }
      });
    }

    if (delItems.length > 0) {
      await directus.deleteItems('sales_orders_items', delItems);
      await directus.deleteItems('sales_order_items', delItems2);
    }

    if (editItems.length > 0) {
      await directus.updateItems('sales_order_items', editItems);
    }

    if (addItems.length > 0) {
      for (let index = 0; index < addItems.length; index++) {
        var addItem = addItems[index];
        delete addItem.sales_order_item.id;

        var orderItemResult = await directus.createItem('sales_order_items', addItem.sales_order_item);
        addItems[index].sales_order_item = { id: orderItemResult.data.id };
      }

      await directus.createItems('sales_orders_items', addItems);
    }

    if (custom_send) {
      await directus.updateItem(
        'sales_orders',
        id,
        {
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          farm_code: selectedCustomer ? selectedCustomer.id : null,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
          status: type,
          notes: notes,
          send_to_email: dataCustomEmailRef.current.send_to_email,
          cc_email: dataCustomEmailRef.current.cc_email,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current
        },
        { send_customer: send_customer, custom_send: custom_send }
      );
    } else {
      await directus.updateItem(
        'sales_orders',
        id,
        {
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          cc_email: email,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current,
          farm_code: selectedCustomer ? selectedCustomer.id : null,
          notes: notes,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
          status: type
        },
        { send_customer: send_customer }
      );
    }

    loadOrderSelected();
    EventEmitter.emit('load_all_orders', { loadStatus: false });

    setLoadingSubmit(false);
    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
  };

  const editNotes = async id => {
    setLoadingSaveNotes(true);

    try {
      await directus.updateItem('sales_orders', id, {
        notes: notes
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    loadOrderSelected(false);
    setLoadingSaveNotes(false);
  }

  const changeDeliveryAddressOption = newValue => {
    handleChangeDeliveryAddressOption(
      newValue,
      deliveryAddressOptionIdRef,
      setDeliveryAddress,
      setDeliveryAddressOptions,
      selectedOrder,
      setSelectedDeliveryAddress
    );
  }

  const onChangeAddress = async (data) => {
    await handleChangeAddress(
      data,
      setDeliveryAddressOptionSelected,
      setInputAddressValue,
      setSelectedDeliveryAddress,
      selectedDeliveryAddressRef
    );
  }

  const handleChangeSalesItem = async (item, oldData) => {
    setItems(
      itemsRef.current.map(dataList =>
        dataList.id === oldData.id
          ? dataList.statusValue === 1
            ? { ...oldData, statusValue: 1, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
            : { ...oldData, statusValue: 2, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
          : dataList
      )
    );
    addItemsCheck(itemCountRef.current);
    countTotal();

    if (oldData.statusValue === 1) {
      oldData.internal_code = item.item_number;
      await syncItem(oldData);
    }

    getItemStockLocations(item, oldData);

  }

  var disabledCheck = selectedCustomer === null || itemCheck === false;

  let delivery_address_check = deliveryAddressOptionIdRef.current == -1 ? inputAddressValueRef.current : selectedDeliveryAddressRef.current.AddressLine1;

  const syncItemConfirmation = async (itemData) => {
    setSelectedSyncItem(itemData);
    setDialogSyncItemOpen(true);
  };

  const syncItem = async (itemData) => {
    setSyncLoading(true);
    var updatedItems = await SyncSingleItemPrice(itemData, itemsRef.current, selectedCustomerRef.current, selectedOrderRef.current ? selectedOrderRef.current.order_date : null, false);
    setItems(updatedItems);
    setSyncLoading(false);
  }

  const showChooseLocationDialog = async (itemData) => {
    if (itemData.internal_code && !openChooseLocationDialogRef.current) {
      setSelectedItem(itemData.id);
      setOpenChooseLocationDialog(true);

      setLocations(itemData.stock_locations || []);
      setSelectedLocation(itemData.selected_location || null);
    }
  }

  const closeChooseLocationsDialog = () => {
    setOpenChooseLocationDialog(false);
    setSelectedLocation("");
    setLocations([]);
  }

  const saveLocation = () => {
    if (!selectedLocationRef.current || !selectedItemRef.current) return;

    // Create a new updated list, ensuring itemsRef.current remains unchanged
    const updatedItems = itemsRef.current.map(item =>
      item.id === selectedItemRef.current
        ? { ...item, selected_location: selectedLocationRef.current, statusValue: item.statusValue === 1 ? 1 : 2 }
        : item
    );

    setItems(updatedItems);
    closeChooseLocationsDialog();
  };

  const getTotalStock = () => {
    var total = 0;
    locationsRef.current.map((loc) => {
      total += loc.Amount ?? 0
    })

    return total;
  }

  const getItemStockLocations = async (item, oldData) => {
    try {
      setLoadingItemStockLocation(true);
      setSelectedItem(oldData.id);
      const response = await directus.api.post(`/custom/sales_items/locations`, {
        Items: [item.item_number],
      });
      // Merge stock locations with existing items
      const updatedItems = mergeItemsWithStockLocations(response, item, oldData);
      setItems(updatedItems);

    } catch (error) {
      console.error("Error fetching locations:", error);
    }

    setLoadingItemStockLocation(false);
  }

  // Separate function to merge stock locations
  const mergeItemsWithStockLocations = (codeLocationList, item, oldData) => {
    return itemsRef.current.map(existingItem => {
      if (existingItem.id === oldData.id) {
        const stockLocations = codeLocationList[item.item_number] || [];
        const selectedLocation = stockLocations.find(location => location.Default) || null;

        return {
          ...existingItem,
          stock_locations: stockLocations,
          selected_location: selectedLocation
        };
      }

      // If no match, return the item unchanged
      return existingItem;
    });
  };

  const getStockColour = (dataItem) => {
    var color = "#ff4f4f";
    if (dataItem.selected_location && dataItem.selected_location.Amount) {
      color = "#82cb43";
    }

    return color;
  }

  const handleLocationChange = (e) => {
    const selectedCode = e.target.value;
    const selectedLoc = locationsRef.current.find(loc => loc.LocationCode === selectedCode) || null;
    setSelectedLocation(selectedLoc);
  };

  return (
    <div style={{ maxWidth: "775px", marginLeft: "auto", marginRight: "auto" }}>
      {orderLoading ?
        <OrderDetailLoadingView />
        :
        add ?
          <AddOrder />
          :
          <>
            <Grid container spacing={0} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Typography noWrap component="div" style={{ paddingRight: '20px', fontWeight: '590', fontSize: '20px' }}>
                  <OverflowTip>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '5px' }}>
                      Sales Order
                      <InsertDriveFileIcon
                        style={{
                          color: 'white',
                          backgroundColor: '#516573',
                          padding: '3px',
                          borderRadius: '50%',
                          fontSize: '20px',
                          marginRight: '5px'
                        }}
                      />

                      {selectedOrderRef.current ? selectedOrderRef.current.status == "draft" ?
                        <CustomButton2
                          variant="contained"
                          style={{ fontSize: "14px", lineHeight: "14px", height: "24px", borderRadius: "10px" }}
                          onClick={() => setDialogSyncAllOpen(true)}
                          disabled={syncLoadingRef.current || itemsLoading || loadingSubmit}
                        >
                          Sync Prices
                        </CustomButton2>
                        : '' : ''}


                      {syncLoadingRef.current ?
                        <SyncingProcessWithText classes={classes} text={"syncing"} />
                        : ''}

                    </div>
                  </OverflowTip>
                </Typography>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ fontWeight: '400', fontSize: '17px', color: 'rgba(60, 60, 67, 0.6)' }}
                >
                  <OverflowTip>
                    {selectedOrderRef.current
                      ? add || selectedOrderRef.current.status === 'draft'
                        ? ''
                        : `${soPrefix}${selectedOrderRef.current.order_number}`
                      : ''}
                  </OverflowTip>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className={classes.flexEnd}>
                <OverflowTip>

                  <div style={{ display: 'flex', columnGap: '10px' }}>
                    {add ? '' :
                      <IconButton onClick={() => handleOpenChangeLog()} data-testid="history-icon" aria-label="delete" size="small" style={{ width: '47px', height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px' }}>
                        <CustomTooltip arrow title={<Typography variant="h6">Order change log</Typography>}>
                          <img src='/static/images/icons/history.svg' alt='History Icon' />
                        </CustomTooltip>
                      </IconButton>
                    }
                    <IconButton aria-label="delete" size="small" style={{ height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', paddingRight: '20px', paddingLeft: '20px' }}>
                      <img src={selectedOrderRef.current ? selectedOrderRef.current.statusIconPath : ''} alt='Status Icon' />
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle2}
                        style={{ paddingLeft: '10px', color: '#374355', lineHeight: '22px', textTransform: 'capitalize' }}
                      >
                        <OverflowTip>{selectedOrderRef.current ? selectedOrderRef.current.status : ''}</OverflowTip>
                      </Typography>
                    </IconButton>
                  </div>
                </OverflowTip>
              </Grid>
            </Grid>
            {isEditable || add ?
              <>
                <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
                  <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px' }}>
                    <>
                      {/* -------------------- CUSTOMER DETAILS -------------------- */}
                      <div className='customer'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Customer Details</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', padding: '5px' }}>
                          <Grid container spacing={0}>
                            <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Customer Name</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8}>
                              <CustomerAutocomplete
                                customerOptionsRef={customerOptionsRef}
                                classes={classes}
                                selectedCustomerRef={selectedCustomerRef}
                                isClickFavouriteRef={isClickFavouriteRef}
                                setIsClickFavourite={setIsClickFavourite}
                                enqueueSnackbar={enqueueSnackbar}
                                setSelectedCustomer={setSelectedCustomer}
                                setDeliveryAddressOptionId={setDeliveryAddressOptionId}
                                setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                                changeDeliveryAddressOption={changeDeliveryAddressOption}
                                itemsRef={itemsRef}
                                setDialogSyncAllOpen={setDialogSyncAllOpen}
                                setCustomerOptions={setCustomerOptions}
                                checkCurrentSelectedCustomer={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <>
                      {/* -------------------- DATE -------------------- */}
                      <div className='date'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Date</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle2}
                            style={{ paddingLeft: '16px' }}
                          >
                            <OverflowTip>{moment(selectedOrderRef.current ? selectedOrderRef.current.order_date : Date.now()).format('DD MMMM YYYY')}</OverflowTip>
                          </Typography>
                        </div>
                      </div>
                    </>
                  </Grid>
                </Grid>

                {/* -------------------- TABLE HEADER -------------------- */}
                <Grid container spacing={0} justifyContent="space-around" style={{ paddingLeft: '5px', marginTop: '20px', width: 'calc(100% - 58px)' }}>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                      <OverflowTip>Code</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                      <OverflowTip>Description</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                      <OverflowTip>Qty</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                      <OverflowTip>Unit Price</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px' }}>
                      <OverflowTip>Item Discount</OverflowTip>
                      {maxOrderItemDiscountPercentRef.current && isEnableItemDiscountPercentRef.current ?
                        <CustomTooltip data-testid="showMaxDiscount" title={<Typography variant="h6">Max discount: {maxOrderItemDiscountPercentRef.current}%</Typography>}>
                          <InfoIcon
                            fontSize="small"
                            style={{ cursor: 'pointer', height: '15px', marginTop: "2px" }}
                          />
                        </CustomTooltip>
                        : ''
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px', paddingRight: '16px', textAlign: 'right' }}>
                      <OverflowTip>Total Price</OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>

                {/* -------------------- TABLE BODY -------------------- */}
                {itemsRef.current ?
                  itemsRef.current.length > 0 ?
                    itemsRef.current.map((dataItem, index) => { // eslint-disable-line array-callback-return
                      const isLastItem = index === itemsRef.current.length - 1;

                      if (dataItem.statusValue !== 3) {
                        return (
                          <Grid key={index} container data-testid='OrderLines' spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
                            <Grid item style={{ width: 'calc(100% - 90px)', background: 'white', paddingLeft: '5px', borderRadius: '10px' }}>
                              <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  {itemsLoading ?
                                    <div style={{ margin: '18px 11px' }}>
                                      <LinearProgress />
                                    </div>
                                    :
                                    <SalesItemCodeDescriptionAutocomplete
                                      index={index}
                                      itemsOptionsRef={itemsOptionsRef}
                                      classes={classes}
                                      dataItem={dataItem}
                                      handleChangeSalesItem={handleChangeSalesItem}
                                      name={"code"}
                                    />
                                  }
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                                  {itemsLoading ?
                                    <div style={{ margin: '18px 11px' }}>
                                      <LinearProgress />
                                    </div>
                                    :
                                    <SalesItemCodeDescriptionAutocomplete
                                      index={index}
                                      itemsOptionsRef={itemsOptionsRef}
                                      classes={classes}
                                      dataItem={dataItem}
                                      handleChangeSalesItem={handleChangeSalesItem}
                                      name={"description"}
                                    />
                                  }
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_quantity_${index}`}
                                    variant='outlined'
                                    className={
                                      dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                                        ? classes.textfield3
                                        : classes.textfield3Required
                                    }
                                    fullWidth
                                    name="quantity"
                                    data-testid={`quantity-${index}`}
                                    type='number'
                                    value={dataItem.quantity ? dataItem.quantity : ''}
                                    onChange={event => changeItems(event, dataItem)}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_unit_price_${index}`}
                                    variant='outlined'
                                    className={classes.textfield3}
                                    fullWidth
                                    name="unit_price"
                                    data-testid={`unit-price-${index}`}
                                    value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : ''}
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                      readOnly: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_discount_${index}`}
                                    variant='outlined'
                                    className={
                                      maxOrderItemDiscountPercentRef.current && isEnableItemDiscountPercentRef.current ?
                                        maxOrderItemDiscountPercentRef.current < (dataItem.item_discount ? dataItem.item_discount : 0)
                                          ?
                                          classes.textfield3Required
                                          :
                                          classes.textfield3 : classes.textfield3
                                    }
                                    fullWidth
                                    name="item_discount"
                                    data-testid={`item-discount-${index}`}
                                    value={dataItem.item_discount ? dataItem.item_discount : 0}
                                    onChange={event => changeItems(event, dataItem)}
                                    InputProps={{
                                      inputComponent: NumberFormatCustomPercent,
                                      'aria-label': 'Item Discount',
                                      readOnly: !isEnableItemDiscountPercentRef.current
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_total_price_${index}`}
                                    variant='outlined'
                                    className={classes.textfield4}
                                    fullWidth
                                    name="total_price"
                                    data-testid={`total-price-${index}`}
                                    value={calculateItemTotal(dataItem.quantity, dataItem.unit_price, dataItem.item_discount)}
                                    inputProps={{
                                      readOnly: true,
                                      'aria-label': 'Total Price',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>

                              <IconButton onClick={() => deleteItems(dataItem)} aria-label="delete" size="small" style={{ width: '22px', height: '22px' }} >
                                <CustomTooltip arrow title={<Typography variant="h6">Delete item</Typography>}>
                                  <img src='/static/images/icons/remove.svg' alt='Remove Icon' />
                                </CustomTooltip>

                              </IconButton>
                            </Grid>
                            <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>

                              {syncLoadingRef.current || itemsLoading || loadingSubmit ?
                                <IconButton
                                  aria-label="sync" size="small" style={{ width: '22px', height: '22px' }} >
                                  <CustomTooltip arrow title={<Typography variant="h6">Sync item's price</Typography>}>
                                    <SyncIcon style={{ color: "#ababab" }} />
                                  </CustomTooltip>
                                </IconButton>
                                :
                                <IconButton onClick={() => syncItemConfirmation(dataItem)}
                                  aria-label="sync" size="small" style={{ width: '22px', height: '22px' }} >
                                  <CustomTooltip arrow title={<Typography variant="h6">Sync item's price</Typography>}>
                                    <SyncIcon style={{ color: "#82cb43" }} />
                                  </CustomTooltip>
                                </IconButton>

                              }

                            </Grid>

                            <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                              {dataItem && dataItem.internal_code ?
                                loadingItemStockLocationRef.current && (selectedItemRef.current == dataItem.id || selectedItemRef.current === "all") ?
                                  <CircularProgress size={18} style={{ color: "#82cb43" }} />
                                  :
                                  <IconButton onClick={() => showChooseLocationDialog(dataItem)}
                                    aria-label="stock_location" size="small" style={{ width: '22px', height: '22px' }}>
                                    <CustomTooltip arrow title={<Typography variant="h6">
                                      Stock Location: {dataItem.selected_location ? dataItem.selected_location.LocationCode ? dataItem.selected_location.LocationCode : dataItem.selected_location.LocationName ? dataItem.selected_location.LocationName : "-" : "-"}
                                      <br />
                                      <span style={{ fontSize: "12px" }}>Stock Level: {dataItem.selected_location ? dataItem.selected_location.Amount ? dataItem.selected_location.Amount : "Out of Stock" : "Out of Stock"}</span>
                                      <br /><span style={{ fontSize: "11px" }}>Click to choose a different location</span></Typography>}>
                                      <ApartmentIcon style={{ color: getStockColour(dataItem), fontSize: "22px" }} />
                                    </CustomTooltip>
                                  </IconButton>
                                :
                                <IconButton aria-label="stock_location" size="small" style={{ width: '22px', height: '22px' }} >
                                  <CustomTooltip arrow title={<Typography variant="h6">Stock Location: - <br /><span style={{ fontSize: "11px" }}>Select an item first</span></Typography>}>
                                    <ApartmentIcon style={{ color: "#a9a9af99", fontSize: "22px" }} />
                                  </CustomTooltip>
                                </IconButton>
                              }
                            </Grid>
                          </Grid >
                        )
                      }
                    })
                    : ''
                  : ''}

                <SyncDialogs />

                {/* -------------------- ADD ITEM -------------------- */}
                <div style={{ display: 'flex', paddingLeft: '6px', marginTop: '15px' }}>
                  <AddItemButton
                    variant="outlined"
                    onClick={() => addItems(items ? items : null)}
                    disabled={syncLoadingRef.current}
                    startIcon={<img src='/static/images/icons/add-item.svg' alt='Add Icon' />}
                  >
                    Add Item
                  </AddItemButton>
                </div>
                <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
                  {/* -------------------- DELIVERY -------------------- */}
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Delivery</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px 0px 5px 5px' }}>
                      <Grid container spacing={0} style={{ paddingBottom: '5px', paddingRight: '5px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery by</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <ThemeProvider theme={theme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className={classes.datepickerEditable}
                                inputVariant='outlined'
                                id="date_picker"
                                inputProps={{
                                  readOnly: true,
                                }}
                                format="dd MMMM yyyy"
                                placeholder="dd/MM/yyyy"
                                value={moment(selectedDeliveryDate).format()}
                                onChange={date => handleDeliveryDateChange(date)}
                                keyboardIcon={<img src='/static/images/icons/calendar.svg' alt='Calendar Icon' />}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider style={{ marginLeft: '11px' }} />
                      </div>
                      <Grid container spacing={0} style={{ padding: '5px 5px 5px 0px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery instructions</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <TextField
                            variant="outlined"
                            className={classes.textfield2Editable}
                            value={deliveryInstructions ?? ''}
                            placeholder='Add delivery notes'
                            name="delivery_instructions"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider style={{ marginLeft: '11px' }} />
                      </div>
                      <Grid container spacing={0} style={{ padding: '5px 5px 0px 0px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery address</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <DeliveryAddressAutocomplete
                            deliveryAddressOptions={deliveryAddressOptions}
                            classes={classes}
                            deliveryAddress={deliveryAddress}
                            setDeliveryAddress={setDeliveryAddress}
                            formattingDeliveryAddress={formattingDeliveryAddress}
                            selectedOrder={selectedOrderRef.current}
                            setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                            setDeliveryAddressOptionId={setDeliveryAddressOptionId}
                            setDeliveryAddressOptionSelected={setDeliveryAddressOptionSelected}
                            setInputAddressValue={setInputAddressValue}
                          />
                        </Grid>
                      </Grid>
                      {deliveryAddressOptionIdRef.current === -1 ?
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingRight: '5px' }}>
                          <GooglePlacesAddressAutocomplete
                            inputAddressValue={inputAddressValueRef.current}
                            setInputAddressValue={setInputAddressValue}
                            onChangeAddress={onChangeAddress}
                            deliveryAddressOptionSelected={deliveryAddressOptionSelected}
                          />
                        </Grid>
                        :
                        selectedCustomer && deliveryAddress && (deliveryAddressOptionIdRef.current === 0 || deliveryAddressOptionIdRef.current > 0) ?
                          <Grid container spacing={0} style={{ padding: '15px 5px 10px 10px', color: '#3c3c4399', fontWeight: 700, fontSize: "15px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {getDeliveryAddress(deliveryAddress)}
                            </Grid>
                          </Grid>
                          : ''
                      }
                    </div>

                    {/* Notes */}
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Notes</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            variant="outlined"
                            className={`${classes.textfield2Editable} ${classes.leftAlignTextfield}`}
                            value={notes ?? ''}
                            placeholder='Insert note...'
                            name="notes"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  {/* --------------------- TOTALS --------------------- */}
                  <Grid item xs={5} sm={5} md={5} lg={5} >
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Totals</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                      {showTotalDetails ?
                        <>
                          <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                                <OverflowTip>
                                  {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                                <OverflowTip>
                                  {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginLeft: '16px' }} />
                          <Grid container spacing={0} justifyContent="space-around" className={showTotalDetails ? classes.gridContainer2 : classes.gridContainer} style={{ paddingRight: '16px' }}>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle4}
                                style={{ lineHeight: '22px' }}
                              >
                                <OverflowTip>Subtotal</OverflowTip>
                              </Typography>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle2}
                                style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                              >
                                <OverflowTip>Including tax</OverflowTip>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle4}
                                style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                              >
                                <OverflowTip>
                                  {isNaN(subTotalIncludingTaxRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalIncludingTaxRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginLeft: '16px' }} />
                        </>
                        :
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle4} style={{ color: 'black' }}>Subtotal</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle4} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount</Typography>
                            {maxOrderDiscountRef.current ?
                              <CustomTooltip data-testid="showMaxDiscount" title={<Typography variant="h6">Max discount: ${formatNumber((maxOrderDiscountRef.current).toFixed(2))}</Typography>}>
                                <InfoIcon
                                  fontSize="small"
                                  style={{ cursor: 'pointer', height: '15px' }}
                                />
                              </CustomTooltip>
                              : ''
                            }
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <TextField
                            variant="outlined"
                            className={
                              maxOrderDiscountRef.current ? maxOrderDiscountRef.current < orderDiscountRef.current
                                ?
                                classes.textfield2EditableRequired
                                : classes.textfield2Editable : classes.textfield2Editable
                            }
                            value={orderDiscountRef.current ? parseFloat(orderDiscountRef.current).toFixed(2) : ''}
                            name="order_discount"
                            data-testid="order-discount"
                            onChange={handleChange}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount %</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <TextField
                            variant="outlined"
                            className={
                              maxOrderDiscountRef.current ? maxOrderDiscountRef.current < orderDiscountRef.current
                                ?
                                classes.textfield2EditableRequired
                                : classes.textfield2Editable : classes.textfield2Editable
                            }
                            value={
                              orderDiscountPercentRef.current !== null && orderDiscountPercentRef.current !== undefined
                                ? String(orderDiscountPercentRef.current)
                                : '0'
                            }
                            name="order_discount_percent"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Shipping</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <TextField
                            variant="outlined"
                            className={classes.textfield2Editable}
                            value={shippingRef.current ? parseFloat(shippingRef.current).toFixed(2) : ''}
                            name="shipping"
                            data-testid="shipping"
                            onChange={handleChange}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider style={{ marginLeft: '16px' }} />
                      <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '5px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ lineHeight: '22px' }}
                          >
                            <OverflowTip>Total</OverflowTip>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                          >
                            <OverflowTip>
                              {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                            </OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </>
              :
              <>
                <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
                  <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px' }}>
                    <>
                      {/* -------------------- CUSTOMER DETAILS -------------------- */}
                      <div className='customer'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Customer Details</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px' }}>
                          <Autocomplete
                            id="customer-options"
                            popupIcon={''}
                            data-testid='customer'
                            options={customerOptionsRef.current}
                            className={classes.autocomplete}
                            disabled
                            getOptionLabel={(option) => option.name ?? ''}
                            value={selectedCustomer ?? ''}
                            sx={{
                              '& .MuiAutocomplete-input': {
                                color: 'black'
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <>
                      {/* -------------------- DATE -------------------- */}
                      <div className='date'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Date</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle2}
                            style={{ paddingLeft: '16px' }}
                          >
                            <OverflowTip>{moment(selectedOrderRef.current ? selectedOrderRef.current.order_date : Date.now()).format('DD MMMM YYYY')}</OverflowTip>
                          </Typography>
                        </div>
                      </div>
                    </>
                  </Grid>
                </Grid>
                {/* -------------------- TABLE HEADER -------------------- */}
                <Grid container spacing={0} style={{ marginTop: '20px' }}>
                  <Grid container spacing={0} justifyContent="space-around" style={{ width: 'calc(100% - 29px)', padding: '0px 16px' }}>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      <Typography noWrap component="div" className={classes.typographyStyle}>
                        <OverflowTip>Code</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                      <Typography noWrap component="div" className={classes.typographyStyle}>
                        <OverflowTip>Description</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                      <Typography noWrap component="div" className={classes.typographyStyle}>
                        <OverflowTip>Qty</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      <Typography noWrap component="div" className={classes.typographyStyle}>
                        <OverflowTip>Unit Price</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      <Typography noWrap component="div" className={classes.typographyStyle}>
                        <OverflowTip>Item Discount</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      <Typography noWrap component="div" className={classes.typographyStyle} style={{ textAlign: 'right' }}>
                        <OverflowTip>Total Price</OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                  </Grid>
                </Grid>

                {/* -------------------- TABLE BODY -------------------- */}
                {itemsRef.current ?
                  itemsRef.current ?
                    itemsRef.current.map((dataItem, index) => {
                      const isLastItem = index === itemsRef.current.length - 1;

                      return (
                        <OrderItemsNotDraftView
                          key={index}
                          dataItem={dataItem}
                          index={index}
                          isLastItem={isLastItem}
                          calculateItemTotal={calculateItemTotal}
                          formatNumber={formatNumber}
                        />
                      )
                    })
                    : ''
                  : ''}
                <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
                  {/* -------------------- DELIVERY -------------------- */}
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Delivery</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', paddingLeft: '16px' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery by</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.datepicker}
                              inputVariant='outlined'
                              id="date_picker"
                              disabled
                              format="dd MMMM yyyy"
                              placeholder="dd/MM/yyyy"
                              value={moment(selectedDeliveryDate).format()}
                              keyboardIcon={<img src='/static/images/icons/calendar.svg' alt='Calendar Icon' />}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider />
                      </div>
                      <Grid container spacing={0}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery instructions</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <TextField
                            variant="outlined"
                            className={classes.textfield}
                            value={deliveryInstructions ?? ''}
                            disabled
                            style={{ paddingRight: '16px' }}
                            name="delivery_instructions"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider />
                      </div>
                      <Grid container spacing={0} style={{ minHeight: '60px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                          <OverflowTip>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery address</Typography>
                          </OverflowTip>
                          <OverflowTip>
                            <Typography className={classes.typographyStyle2} style={{ color: 'rgba(60, 60, 67, 0.6)' }}>
                              {selectedDeliveryAddressRef.current.AddressName}
                            </Typography>
                          </OverflowTip>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={classes.deliveryaddressstyle} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div>
                              {
                                selectedOrderRef.current
                                  ? `${selectedOrderRef.current.delivery_address_line_1 ?? ''}${selectedOrderRef.current.delivery_address_line_2 ? ', ' + selectedOrderRef.current.delivery_address_line_2 + ',' : ''}`
                                  : ''
                              }
                            </div>
                            <div>
                              {
                                selectedOrderRef.current
                                  ?
                                  `${selectedOrderRef.current.delivery_address_city
                                    ? selectedOrderRef.current.delivery_address_city + ' '
                                    : ''}` +
                                  `${selectedOrderRef.current.delivery_address_state
                                    ? selectedOrderRef.current.delivery_address_state + ' '
                                    : ''}` +
                                  `${selectedOrderRef.current.delivery_address_postcode
                                    ? selectedOrderRef.current.delivery_address_postcode + ' '
                                    : ''}`
                                  : ''
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Notes</OverflowTip>
                    </Typography>

                    {selectedOrder && (selectedOrder.status == 'sent' || selectedOrder.status == 'completed') ?
                      <>
                        <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px' }}>
                          <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextField
                                variant="outlined"
                                className={`${classes.textfield2Editable} ${classes.leftAlignTextfield}`}
                                value={notes ?? ''}
                                placeholder='Insert note...'
                                name="notes"
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {loadingSaveNotes ?
                          <CircularProgress
                            className={classes.circular}
                            style={{
                              color: '#67AC2D',
                              width: '20px',
                              height: '20px',
                              marginTop: '10px',
                              display: 'flex',
                              marginLeft: 'auto',
                              marginRight: '16px'
                            }}
                          />
                          :
                          <CustomButton
                            style={{ marginTop: '10px', display: 'flex', marginLeft: 'auto', marginRight: '16px' }}
                            variant="contained"
                            onClick={() => editNotes(selectedOrder.id)}
                            disabled={loadingSaveNotes}
                            startIcon={<DescriptionIcon style={{ fontSize: "17px" }} />}
                          >
                            Save
                          </CustomButton>
                        }
                      </>
                      :
                      <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', paddingLeft: '16px' }}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              variant="outlined"
                              className={`${classes.textfield} ${classes.leftAlignTextfield}`}
                              value={notes ?? ''}
                              disabled
                              style={{ paddingRight: '16px' }}
                              name="notes"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    }
                  </Grid>
                  {/* --------------------- TOTALS --------------------- */}
                  <Grid item xs={5} sm={5} md={5} lg={5} >
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Totals</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                      {showTotalDetails ?
                        <>
                          <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                                <OverflowTip>
                                  {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                                <OverflowTip>
                                  {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginLeft: '16px' }} />
                          <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '16px' }}>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle4}
                                style={{ lineHeight: '22px' }}
                              >
                                <OverflowTip>Subtotal</OverflowTip>
                              </Typography>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle2}
                                style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                              >
                                <OverflowTip>Including tax</OverflowTip>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle4}
                                style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                              >
                                <OverflowTip>
                                  {isNaN(subTotalIncludingTaxRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalIncludingTaxRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginLeft: '16px' }} />
                        </>
                        :
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle4} style={{ color: 'black' }}>Subtotal</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle4} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                            <OverflowTip>-{isNaN(orderDiscountRef.current) ? 0 : '$' + formatNumber((Math.round(orderDiscountRef.current * 100) / 100).toFixed(2))}</OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                        <Grid item xs={5} sm={5} md={5} lg={5} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount %</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                            <OverflowTip>{orderDiscountPercentRef.current !== null && orderDiscountPercentRef.current !== undefined
                              ? String(orderDiscountPercentRef.current)
                              : '0'}</OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Shipping</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                            <OverflowTip>{isNaN(shippingRef.current) ? 0 : '$' + formatNumber((Math.round(shippingRef.current * 100) / 100).toFixed(2))}</OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ marginLeft: '16px' }} />
                      <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '5px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ lineHeight: '22px' }}
                          >
                            <OverflowTip>Total</OverflowTip>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                          >
                            <OverflowTip>
                              {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                            </OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            {/* -------------------- BUTTON -------------------- */}
            <ButtonsView
              selectedOrder={selectedOrderRef.current}
              loadOrderSelected={loadOrderSelected}
              disabledCheck={disabledCheck}
              delivery_address_check={delivery_address_check}
              selectedCustomer={selectedCustomer}
              editSubmit={editSubmit}
              setDataCustomEmail={setDataCustomEmail}
              dataCustomEmail={dataCustomEmailRef.current}
              customEmailLoading={customEmailLoading}
              setCustomEmailLoading={setCustomEmailLoading}
              setIsCustomEmail={setIsCustomEmail}
              isCustomEmail={isCustomEmailRef.current}
              closeCustomEmail={closeCustomEmail}
              loadingSubmit={loadingSubmit}
              add={add}
            />
          </>
      }

      <Dialog
        isopen={`${openChangeLog}`}
        open={openChangeLog}
        fullWidth={true}
        onClose={() => setOpenChangeLog(false)}
        className={classes.DialogContent}
      >
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>Date & Time</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>Status Update</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>User</OverflowTip>
              </Typography>
            </Grid>
          </Grid>
          {logDataLoading ?
            <CircularProgress key='load-progress-sales-orders' className={classes.circular} style={{ color: '#67AC2D' }} />
            :
            logData.length > 0
              ? logData.map((dataLog, index) => {
                return (
                  <Grid container spacing={0} key={index} className={classes.gridContainer3}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{dataLog.created_on ? moment(dataLog.created_on).format('DD/MM/YYYY hh:mm A') : ''}</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{orderEventType ? orderEventType[dataLog.type] : dataLog.type}</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{dataLog.owner ? dataLog.owner.first_name : ''} {dataLog.owner ? dataLog.owner.last_name : ''}</OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
              : null
          }
        </DialogContent>
      </Dialog>

      <Dialog open={openChooseLocationDialogRef.current} onClose={closeChooseLocationsDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title" style={{ padding: "20px 30px 16px 30px" }}>
          <Typography component="div" style={{ fontWeight: "800", fontSize: "16px" }}>
            Stock Locations
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px', padding: "8px 30px" }}>
          <Grid container spacing={1} style={{ fontWeight: "bold", marginBottom: "5px" }}>
            <Grid item xs={1}></Grid> {/* Empty for radio buttons */}
            <Grid item xs={4}>
              <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
                <OverflowTip>Location Code</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
                <OverflowTip>Location Name</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
                <OverflowTip>Stock</OverflowTip>
              </Typography>
            </Grid>
          </Grid>

          <RadioGroup
            value={selectedLocationRef.current?.LocationCode || ""}
            onChange={handleLocationChange}
          >
            {locationsRef.current.map((loc, index) => (
              <Grid container spacing={1} key={index} alignItems="center">
                <Grid item xs={1}>
                  <FormControlLabel
                    value={loc.LocationCode} // Ensure value is a string
                    control={<Radio />}
                    label=""
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography noWrap component="div" style={{ paddingLeft: "5px" }}>
                    <OverflowTip>{loc.LocationCode}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography noWrap component="div" style={{ paddingLeft: "5px" }}>
                    <OverflowTip>{loc.LocationName}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography noWrap component="div" style={{ paddingLeft: "5px" }}>
                    <OverflowTip>{loc.Amount}</OverflowTip>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </RadioGroup>

          <Grid container spacing={1} style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "15px" }}>
            <Typography noWrap component="div" style={{ paddingLeft: '5px', fontWeight: "800" }}>
              <OverflowTip>Total Stock: {getTotalStock()}</OverflowTip>
            </Typography>
          </Grid>

        </DialogContent>

        <DialogActions style={{ height: '60px', padding: '8px 20px' }}>
          <div>
            <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeChooseLocationsDialog}>
              Cancel
            </CancelButton>
            <SubmitButton
              style={{ marginLeft: "5px" }}
              id="save_btn"
              size="small"
              variant="contained"
              onClick={() => saveLocation()}
              disabled={!selectedLocationRef.current}
            >
              Save
            </SubmitButton>
          </div>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default SelectedOrderView