import React from 'react';
import { useStyles } from '../style.js';
import { OverflowTip } from "src/components/OverflowTip";
import {
  Grid,
  Typography,
  Tooltip,
  withStyles
} from "@material-ui/core";
import { calculateItemTotal } from 'src/views/SalesOrders/SalesOrderFunction';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { IconButton } from "@mui/material";

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "#929292"
  }
})(Tooltip);

const OrderItemsNotDraftView = ({
  dataItem,
  index,
  isLastItem,
  formatNumber
}) => {
  const classes = useStyles();

  return (
    <div>
      <Grid key={index} container data-testid='OrderLines' spacing={0} style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
        <Grid key={index} container spacing={0} justifyContent="space-around" style={{ width: 'calc(100% - 29px)', marginBottom: isLastItem ? '0px' : '5px', background: 'white', padding: '0px 16px', borderRadius: '10px', height: '44px', display: 'flex', alignItems: 'center' }}>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle2}>
              <OverflowTip>{dataItem.internal_code ? dataItem.internal_code : ''}</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle2}>
              <OverflowTip>{dataItem.description ? dataItem.description : ''}</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle2} style={{ paddingLeft: '2px' }}>
              <OverflowTip>{dataItem.quantity ? dataItem.quantity : ''}</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle2}>
              <OverflowTip>{isNaN(dataItem.unit_price) ? 0 : '$' + formatNumber((Math.round(dataItem.unit_price * 100) / 100).toFixed(2))}</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle2}>
              <OverflowTip>{dataItem.item_discount ? `${dataItem.item_discount}%` : '0%'}</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Typography noWrap component="div" className={classes.typographyStyle2} style={{ textAlign: 'right' }}>
              <OverflowTip>{calculateItemTotal(dataItem.quantity, dataItem.unit_price, dataItem.item_discount)}</OverflowTip>
            </Typography>
          </Grid>
        </Grid>
        <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
          <IconButton aria-label="stock_location" size="small" style={{ width: '22px', height: '22px' }} >
            <CustomTooltip arrow title={<Typography variant="h6">Stock Location: {dataItem.location_code ? dataItem.location_code : "-"}</Typography>}>
              <ApartmentIcon style={{ color: "#a2a2a2", fontSize: "22px", marginBottom: "1px" }} />
            </CustomTooltip>
          </IconButton>
        </Grid>
      </Grid >
    </div>
  )
}

export default OrderItemsNotDraftView