/* eslint-disable no-constant-condition */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import directus from "../../../services/directus";
import moment from "moment";
import { formatNumber, configurationFields } from 'src/components/ReusableFunction';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { useStyles } from '../approvaldetailstyle';
import { getStatusIconPath, LoadingButtonCustom } from 'src/components/ReusableStyle';
import { OverflowTip } from "src/components/OverflowTip";
import { Grid, Typography } from "@material-ui/core";
import { Divider, Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';

function AllApprovalHistory
() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [isMyApprovalsHistoryGroupOpen, setIsMyApprovalsHistoryGroupOpen] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [, setPage, pageRef] = useStateRef(1);
  const [showPOAccount, setShowPOAccount] = useState(false);
  const [, setTotalPage, totalPageRef] = useStateRef("");
  const [, setApprovalsHistoryData, approvalsHistoryDataRef] = useStateRef(null);
  const [, setSelectedOrderId, selectedOrderIdRef] = useStateRef(window.location.pathname.split('/').pop());
  const [approvalHistoryLoading, setApprovalHistoryLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true; // component mount
    if (!initialized) {
      getConfigurationSettings();
      setInitialized(true);
      loadingApprovalsHistory();
    }

    const onSearch = async eventData => {
      setPage(1);
      await loadingApprovalsHistory(eventData.text);
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener = EventEmitter.addListener('/approvals-history/:id', onSearch);

    return () => {
      isMounted.current = false;
      listener.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration', { fields: configurationFields});
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (isMounted.current && result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_po_account === true) {
          setShowPOAccount(true);
        }
      }
    }
  }

  const loadingApprovalsHistory = async (searchWord, loadMoreStatus = false) => {
    if (!loadMoreStatus) {
      setApprovalHistoryLoading(true);
    }
    
    let currentPage = pageRef.current;

    let approvalsHistory = [];

    try {
      const params_filter = [
        "*",
        "level.id",
        "level.level",
        "employee.id",
        "employee.user.id",
        "employee.user.first_name",
        "employee.user.last_name",
        "employee.supervisors.supervisor.id",
        "employee.supervisors.supervisor.user.id",
        "employee.supervisors.supervisor.user.first_name",
        "employee.supervisors.supervisor.user.last_name",
        "approved_order.id",
        "approved_order.supplier.id",
        "approved_order.supplier.code",
        "approved_order.supplier.name",
        "approved_order.farm_code.id",
        "approved_order.farm_code.code",
        "approved_order.farm_code.name",
        "approved_order.order_items.order_item.quantity",
        "approved_order.order_items.order_item.unit_price",
        "approved_order.status",
        "approved_order.order_date",
        "approved_order.order_number",
        "approved_order.invoices.orders_id.id"
      ];

      if(loadMoreStatus) {
        currentPage+=1;
      }
  
      var request_header = {
        fields: params_filter,
        meta: "filter_count",
        limit: 100,
        page: currentPage,
      }
  
      if (searchWord) {
        request_header['q'] = searchWord;
      }
      
      var params = Object.entries(request_header).map(e => e.join('=')).join('&');
      approvalsHistory = await directus.api.post(`/custom/orders/approval_history?${params}`);
    } catch (error) {
      enqueueSnackbar(ErrorMessage(error), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    if (isMounted.current) {
      setTotalPage(approvalsHistory.meta.page_count);
    }

    let approvalsHistoryData = approvalsHistory.data;

    if (approvalsHistoryData) {
      approvalsHistoryData.forEach((value, index) => {
        var supplier_name = "";
        var customer_name = "";
  
        if (value.approved_order.supplier !== null) {
          if (value.approved_order.supplier.name) {
            supplier_name = value.approved_order.supplier.name;
          }
        }
  
        if (value.approved_order.farm_code !== null) {
          if (value.approved_order.farm_code.name) {
            customer_name = value.approved_order.farm_code.name;
          }
        }
  
        approvalsHistoryData[index].approved_order.supplier_name = supplier_name;
        approvalsHistoryData[index].approved_order.customer_name = customer_name;
  
        let totalOrder = 0;
        //eslint-disable-next-line array-callback-return
        value.approved_order.order_items.forEach(data => {
          if (data.order_item !== null) {
            totalOrder = totalOrder + data.order_item.unit_price * data.order_item.quantity;
          }
        });
  
        approvalsHistoryData[index].approved_order.total_order = totalOrder;
        approvalsHistoryData[index].approved_order.statusIconPath = getStatusIconPath(value.approved_order.status);
      });

      if(loadMoreStatus) {
        let currentApprovalsHistory = approvalsHistoryDataRef.current;
        let newApprovalsHistory = currentApprovalsHistory.concat(approvalsHistoryData);
        
        if (isMounted.current) {
          setApprovalsHistoryData(newApprovalsHistory);
        }
      } else {
        if (isMounted.current) {
          setApprovalsHistoryData(approvalsHistoryData);
        }
      }
    }

    if (isMounted.current) {
      setPage(currentPage);
      setLoadMore(false);
      setApprovalHistoryLoading(false);
    }
  }

  const handleClickOrder = (e, orderId) => {
    e.stopPropagation();
    navigate(`/approvals-history/${orderId}`);
    setSelectedOrderId(orderId);
    EventEmitter.emit("selected_approval_history");
  }

  const handleClickMyApprovalsHistoryGroup = () => {
    setIsMyApprovalsHistoryGroupOpen(!isMyApprovalsHistoryGroupOpen);
  };

  const handleClickLoadMore = () => {
    setLoadMore(!loadMore);
    loadingApprovalsHistory('', true);
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      {!approvalHistoryLoading ?
        approvalsHistoryDataRef.current ?
          approvalsHistoryDataRef.current.length > 0 ? (
            <div>
              <div onClick={() => handleClickMyApprovalsHistoryGroup()}>
                <div className='month-header-wrapper' style={{ display: 'flex', marginBottom: '3px', cursor: 'pointer' }}>
                  <Typography noWrap component="div" className={classes.headerStyle}>
                    <OverflowTip>My Approvals History</OverflowTip>
                  </Typography>
                  <div className={classes.arrowDownWrapper}>
                    {isMyApprovalsHistoryGroupOpen ? 
                      <KeyboardArrowDownIcon style={{ color: 'rgba(0, 122, 255, 1)' }} data-testid='expandIcon' />
                    :
                      <KeyboardArrowRightIcon style={{ color: 'black' }} data-testid='collapseIcon' />
                    }
                  </div>
                </div>
                <div style={{ display: isMyApprovalsHistoryGroupOpen ? 'block' : 'none' }}>
                  {approvalsHistoryDataRef.current && approvalsHistoryDataRef.current.length > 0 && approvalsHistoryDataRef.current.map((approval, index) => {
                    const isHighlight = approval.id == selectedOrderIdRef.current;
                    const isLastIndex = index === approvalsHistoryDataRef.current.length - 1;

                    return (
                      <div
                        key={`order-list-${index}`}
                        className='order-list-wrapper'
                        data-testid={`approval-history-item-${index}`}
                        style={{ borderRadius: '10px', backgroundColor: 'white', border: isHighlight ? '2px solid #82CB43' : 'none', cursor: 'pointer', marginBottom: isLastIndex ? '25px' : '10px' }}
                        onClick={(e) => handleClickOrder(e, approval.id)}
                      >   
                        <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                            <img src={"/static/images/icons/po-icon.svg"} alt='Purchase Order Icon' />
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem} style={{ paddingLeft: '5px' }}>
                            <Typography component="div" className={classes.typographyStyle3}>
                              <OverflowTip>
                                {approval.approved_order.status === 'awaiting_approval' ?
                                  approval.employee.supervisors && approval.employee.supervisors.length > 0 ?
                                    `${approval.employee.supervisors[0].supervisor.user.first_name} ${approval.employee.supervisors[0].supervisor.user.last_name}`
                                  : '-'
                                :
                                  `${approval.employee.user.first_name} ${approval.employee.user.last_name}`
                                }
                              </OverflowTip>
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                            >
                              <OverflowTip>{moment(approval.approved_date).format('DD/MM/YYYY')}</OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <div style={{ backgroundColor: 'white' }}>
                          <Divider style={{ marginLeft: '49px'}} />
                        </div>
                        <Grid container spacing={0} justifyContent="space-around" className={showPOAccount ? classes.gridContainer2 : classes.gridContainer}>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                          </Grid>
                          <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridItem} style={{ paddingLeft: '5px' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                            >
                              <OverflowTip>Supplier: {approval.approved_order.supplier_name ?? ''}</OverflowTip>
                              {showPOAccount ?
                                <OverflowTip>Account: {approval.approved_order.farm_code ? approval.approved_order.farm_code.name ?? '' : ''}</OverflowTip>
                              : ''}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <KeyboardArrowRightIcon style={{ color: 'rgba(60, 60, 67, 0.3)'}} />
                          </Grid>
                        </Grid>
                        <div style={{ backgroundColor: 'white' }}>
                          <Divider style={{ marginLeft: '49px'}} />
                        </div>
                        <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                            <img src={approval.approved_order.statusIconPath} alt='Status Icon' />
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem} style={{ paddingLeft: '5px' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle3}
                              style={{ fontSize: '15px', textTransform: 'capitalize' }}
                            >
                              <OverflowTip>{approval.approved_order.status === 'awaiting_approval' ? 'Awaiting Approval' : approval.approved_order.status}</OverflowTip>
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                {approval.approved_order.invoices ?
                                  approval.approved_order.invoices.length > 0 ?
                                    <InsertDriveFileIcon
                                      style={{
                                        color: 'white',
                                        backgroundColor: 'rgba(60, 60, 67, 0.6)',
                                        padding: '3px',
                                        borderRadius: '50%',
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                        marginRight: '5px'
                                      }}
                                    />
                                  : ''
                                : ''}
                                <OverflowTip>{isNaN(approval.approved_order.total_order) ? 0 : '$' + formatNumber(approval.approved_order.total_order.toFixed(2))}</OverflowTip>
                              </div>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
          : ''
        : ''
      : (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
        </Grid>
      )}

      {!approvalHistoryLoading && approvalsHistoryDataRef.current && approvalsHistoryDataRef.current.length > 0 ?
        pageRef.current < totalPageRef.current ?
          <LoadingButtonCustom
            loading={loadMore}
            loadingPosition="end"
            endIcon={<AddIcon />}
            variant="outlined"
            onClick={() => handleClickLoadMore()}
          >
            <span style={{ textTransform: 'capitalize', fontFamily: 'SF Pro' }}>Load More</span>
          </LoadingButtonCustom>
        :''
      :''
      }
    </div>
  )
}

export default AllApprovalHistory
