import Enumerable from "linq";
import { getCache, setCache } from 'src/utils/KeyValueCache';
import directus from "../../services/directus";
import ErrorMessage from 'src/components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import {
  checkEnableClick,
  generateDeliveryOption,
  formatNumber,
  generateDeliveryAddress
} from 'src/components/ReusableFunction';
import { geocodeByAddress } from 'react-google-places-autocomplete';

export const loadCustomer = async (
  customerFields,
  isMounted,
  setCustomerOptions,
  enqueueSnackbar
) => {
  try {
    var customer_filter = { status: 'published' };
    var Customers = null;

    var customerCache = getCache('customers');
    if (!customerCache) {
      Customers = await directus.getItems('customers', {
        fields: customerFields,
        sort: 'name',
        filter: customer_filter
      });
      setCache('customers', Customers.data);
    } else {
      Customers = { data: customerCache };
    }

    var customers_data = Customers.data;
    var fav_customers = [];

    if (localStorage.directus_employee) {
      var directus_employee = JSON.parse(localStorage.directus_employee);
      if (directus_employee.favourites_customers_po) {
        fav_customers = directus_employee.favourites_customers_po;
      }
    }

    customers_data.forEach((value, index) => {
      if (fav_customers.indexOf(value.id) >= 0) {
        customers_data[index].isFavourite = true;
      } else {
        customers_data[index].isFavourite = false;
      }
    });

    customers_data = Enumerable.from(customers_data)
      .orderBy(item => item.name)
      .orderByDescending(item => item.isFavourite)
      .toArray();

    if (isMounted.current) {
      setCustomerOptions(customers_data);
    }
  } catch (e) {
    enqueueSnackbar(ErrorMessage(e), {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'error',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
  }
};

export const getConfigurationSettings = async (
  isMounted,
  setShowTotalDetails,
  setEnableItemDiscountPercent,
  setMaxOrderItemDiscountPercent,
  setMaxOrderDiscount,
  setSoPrefix = null
) => {
  var result = await directus.getItems('configuration', {
    fields: "show_sales_orders_total_details, enable_item_discount_percent, max_order_item_discount_percent, max_order_discount, so_prefix"
  });

  if (isMounted.current && result.data.length > 0) {
    var data = result.data[0];
    if (data) {
      if (data.show_sales_orders_total_details === true) setShowTotalDetails(true);
      if (typeof setSoPrefix === 'function' && data.so_prefix) setSoPrefix(data.so_prefix);
      if (data.enable_item_discount_percent === true) setEnableItemDiscountPercent(true);
      if (data.max_order_item_discount_percent) setMaxOrderItemDiscountPercent(data.max_order_item_discount_percent);
      if (data.max_order_discount) setMaxOrderDiscount(data.max_order_discount);
    }
  }
};

export const loadItems = async (
  setItemsLoading,
  setItemsOptions,
  enqueueSnackbar
) => {
  setItemsLoading(true);
  try {
    var Items = null;
    var itemsCache = getCache('so_items');
    if (!itemsCache) {
      Items = await directus.getItems('items', {
        fields: '*',
        sort: 'name',
        limit: -1,
        filter: {
          status: { eq: 'published' }
        }
      });
      setCache('so_items', Items.data);
    } else {
      Items = { data: itemsCache };
    }

    var items_data = Items.data;
    items_data = Enumerable.from(items_data)
      .orderBy(item => item.name)
      .toArray();

    setItemsOptions(items_data);
  } catch (e) {
    enqueueSnackbar(ErrorMessage(e), {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'error',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
  }
  setItemsLoading(false);
};

export const resetCustomer = async (
  isClickFavouriteRef,
  customerOptionsRef,
  enqueueSnackbar,
  setIsClickFavourite
) => {
  try {
    if (isClickFavouriteRef.current) {
      var fav_customers_data = Enumerable.from(customerOptionsRef.current)
        .where(w => w.isFavourite === true)
        .select(data => data.id)
        .toArray();

      var fav_customers = [];
      var directus_employee = null;
      if (localStorage.directus_employee) {
        directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      var isUpdateFavourite = false;
      if (fav_customers.length === 0 && fav_customers_data.length === 0) {
        isUpdateFavourite = true;
      } else if (fav_customers.length !== fav_customers_data.length) {
        isUpdateFavourite = true;
      } else {
        isUpdateFavourite = true;
        fav_customers_data.forEach(value => {
          if (fav_customers.indexOf(value) >= 0) {
            isUpdateFavourite = false;
          }
        });

        if (!isUpdateFavourite) {
          isUpdateFavourite = true;
          fav_customers.forEach(value => {
            if (fav_customers_data.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });
        }
      }

      if (isUpdateFavourite) {
        directus_employee.favourites_customers_po = fav_customers_data;
        localStorage.directus_employee = JSON.stringify(directus_employee);
        await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
      }
    }
  } catch (e) {
    enqueueSnackbar(ErrorMessage(e), {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'error',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
  }
  setIsClickFavourite(false);
};

export const clickCustomerFavorite = (
  option,
  setIsClickFavourite,
  customerOptionsRef,
  setCustomerOptions
) => {
  if (checkEnableClick(option)) {
    setIsClickFavourite(true);
    let customerOptionsData = customerOptionsRef.current.map((data) => {
      if (data.id === option.id) {
        return { ...data, isFavourite: !option.isFavourite };
      }
      return data;
    });

    const updateCustomerOption = Enumerable.from(customerOptionsData)
      .orderBy(item => item.name)
      .orderByDescending(item => item.isFavourite)
      .toArray();

    setCustomerOptions(updateCustomerOption);
  }
};

export const calculateSubTotal = (items) => {
  if (items) {
    let subtotal = 0;

    //eslint-disable-next-line array-callback-return
    items.map(data => {
      if (data.statusValue !== 3) {
        if (data.unit_price && data.quantity) {
          let item_total = data.unit_price * data.quantity;
          if (data.item_discount) {
            if (data.item_discount !== '0' && data.item_discount !== 0) {
              const item_total_discount = (item_total * data.item_discount) / 100;
              item_total -= item_total_discount;
            }
          }
          subtotal = subtotal + parseFloat(item_total);
        }
      }
    });

    return subtotal;
  }

  return 0;
}

export const calculateTotal = (
  items,
  orderDiscount,
  shipping,
  setSubTotalIncludingTax,
  setSubTotal,
  setTotalTax,
  setTotalOrderItem,
  setOrderDiscount,
  setOrderDiscountPercent,
  skipDiscountPercent = false
) => {
  if (items) {
    let subtotal = calculateSubTotal(items);
    const tax = (10 * subtotal) / 100;
    let total = subtotal;

    setSubTotalIncludingTax(total);

    if (total > 0) {
      if (orderDiscount > 0) {
        total -= parseFloat(orderDiscount);
      }
      if (shipping > 0) {
        total += parseFloat(shipping);
      }
    }

    setSubTotal(subtotal.toFixed(2));
    setTotalTax(tax.toFixed(2));
    setTotalOrderItem(total.toFixed(2));

    if (!skipDiscountPercent) {
      let discount_percent = subtotal && orderDiscount ? (orderDiscount / subtotal) * 100 : 0;
      if (discount_percent > 100) discount_percent = 100;
      setOrderDiscountPercent(subtotal && orderDiscount ? parseFloat(discount_percent.toFixed(3)) : 0);
    }
  } else {
    setOrderDiscount(0);
    if (!skipDiscountPercent) setOrderDiscountPercent(0);
  }
};

export const calculateItemTotal = (qty, unit_price, item_discount) => {
  let total = unit_price;

  if (qty && qty > 0) {
    total = qty * unit_price;
    if (item_discount) {
      if (item_discount !== '0' && item_discount !== 0) {
        const total_item_discount = (total * item_discount) / 100;
        total -= total_item_discount;
      }
    }
  }

  return isNaN(total) ? '$0.00' : '$' + formatNumber((Math.round(total * 100) / 100).toFixed(2));
};

export const getDeliveryAddress = (deliveryAddress) => {
  var address = "";

  if (deliveryAddress) {
    address = `${deliveryAddress.address_line_1 ? deliveryAddress.address_line_1 : ''}` +
      `${deliveryAddress.address_line_2 ? ', ' + deliveryAddress.address_line_2 : ''}` +
      `${deliveryAddress.city ? ', ' + deliveryAddress.city + ' ' : ''}` +
      `${deliveryAddress.state ? deliveryAddress.state + ' ' : ''}` +
      `${deliveryAddress.post_code ? deliveryAddress.post_code + '' : ''}`
  }

  return address;
}

export const handleChangeDeliveryAddressOption = (
  newValue,
  deliveryAddressOptionIdRef,
  setDeliveryAddress,
  setDeliveryAddressOptions,
  selectedOrder = null,
  setSelectedDeliveryAddress = null
) => {
  let newDeliveryAddressOption = generateDeliveryOption(newValue);
  const selectedAddressFromOption = newDeliveryAddressOption.filter(
    address => address.id === deliveryAddressOptionIdRef.current
  );

  if (selectedAddressFromOption.length > 0) {
    setDeliveryAddress(selectedAddressFromOption[0]);
    formattingDeliveryAddress(selectedAddressFromOption[0], selectedOrder, setSelectedDeliveryAddress);
  } else {
    setDeliveryAddress('');
  }

  setDeliveryAddressOptions(newDeliveryAddressOption);
};

export const handleChangeAddress = async (
  data,
  setDeliveryAddressOptionSelected,
  setInputAddressValue,
  setSelectedDeliveryAddress,
  selectedDeliveryAddressRef
) => {
  setDeliveryAddressOptionSelected(data);
  if (data) {
    let address = data.label;
    let subpremise = '';
    let street_number = '';
    let route = '';
    let check_address = '';
    let Zip = '';
    let State = '';
    let City = '';

    setInputAddressValue(address);
    const results = await geocodeByAddress(address);

    if (results[0]) {
      results[0].address_components.forEach((value) => {
        if (value.types) {
          value.types.forEach((types_value) => {
            if (types_value === "subpremise") {
              subpremise = "U" + value.long_name + '/';
              check_address = "U" + value.long_name + '/';
            }
            if (types_value === "street_number") {
              street_number = value.long_name + ' ';
              check_address += ' ' + value.long_name;
            }
            if (types_value === "route") {
              route = value.long_name;
              check_address += ' ' + value.long_name;
            }
            if (types_value === "postal_code") {
              Zip = value.long_name;
            }
            if (types_value === "administrative_area_level_1") {
              State = value.short_name;
            }
            if (types_value === "locality") {
              City = value.long_name;
            }
          });
        }
      });
    }

    if (check_address.length <= 30) {
      setSelectedDeliveryAddress({
        ...selectedDeliveryAddressRef.current,
        AddressLine1: subpremise + street_number + route,
        AddressLine2: ''
      });
    } else {
      setSelectedDeliveryAddress({
        ...selectedDeliveryAddressRef.current,
        AddressLine1: subpremise,
        AddressLine2: street_number + ' ' + route
      });
    }

    setSelectedDeliveryAddress({
      ...selectedDeliveryAddressRef.current,
      AddressName: 'Custom',
      AddressCity: City,
      AddressState: State,
      AddressPostcode: Zip,
    });
  } else {
    setInputAddressValue('');
  }
};

export const formattingDeliveryAddress = (address, selectedOrder = null, setSelectedDeliveryAddress = null) => {
  // Call generateDeliveryAddress based on whether selectedOrder is provided
  const formatting_delivery_address = generateDeliveryAddress(address, selectedOrder)

  // Update selected delivery address state
  if (typeof setSelectedDeliveryAddress === 'function') {
    setSelectedDeliveryAddress({
      AddressName: formatting_delivery_address.AddressName,
      AddressLine1: formatting_delivery_address.AddressLine1,
      AddressLine2: formatting_delivery_address.AddressLine2,
      AddressCity: formatting_delivery_address.AddressCity,
      AddressState: formatting_delivery_address.AddressState,
      AddressPostcode: formatting_delivery_address.AddressPostcode,
    });
  }

};

export const updateSelectedCustomer = (
  newValue,
  setSelectedCustomer,
  setDeliveryAddressOptionId,
  setSelectedDeliveryAddress,
  changeDeliveryAddressOption,
  itemsRef,
  setDialogSyncAllOpen
) => {
  setSelectedCustomer(newValue);
  setDeliveryAddressOptionId(0);
  setSelectedDeliveryAddress({
    AddressLine1: '',
    AddressLine2: '',
    AddressName: '',
    AddressCity: '',
    AddressState: '',
    AddressPostcode: '',
  });

  changeDeliveryAddressOption(newValue);

  if (itemsRef.current) {
    if (itemsRef.current.length > 0) {
      var itemsWithCode = Enumerable.from(itemsRef.current)
        .where(x => x.internal_code !== '' && x.internal_code != null)
        .toArray();

      if (itemsWithCode.length > 0) {
        setDialogSyncAllOpen(true);
      }
    }
  }
};