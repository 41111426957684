import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { v4 as uuidv4 } from "uuid";
import { SubmitButton, CancelButton } from 'src/components/ReusableStyle';
import directus from '../../services/directus';
import { useStylesEmployees, AddAddressButton, RemoveButton } from './style.js';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
  },
})(TextField);

const CompanySettings = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [deliveryAddressCheck, setDeliveryAddressCheck] = useState(true);
  const [deliveryAddress, setDeliveryAddress, deliveryAddressRef] = useStateRef([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataConfig, setDataConfig, dataConfigRef] = useStateRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useRef(true);
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setInitialized(true);
      loadCompanySettings();
    }

    return () => {
      isMounted.current = false;
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadCompanySettings = async () => {
    try {
      var result = await directus.getItems('company_settings', { fields: '*, delivery_address.*' });
      
      if (isMounted.current) {
        setDataConfig(result.data[0]);

        if (result.data[0].delivery_address && result.data[0].delivery_address.length > 0) {
          const DeliveryAddress = result.data[0].delivery_address

          let itemData = [];
          DeliveryAddress.forEach((data, index) => {
            itemData[index] = { ...data, statusValue: 0, count: index };
          });
          setDeliveryAddress(itemData);
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    setDataConfig({ ...dataConfigRef.current, [name]: value })
  }

  const handleUpdate = async () => {
    setLoadingUpdate(true);
    try {
      var data = dataConfigRef.current;
      const body = {
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        abn_number: data.abn_number,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        state: data.state,
        postcode: data.postcode,
      }

      await directus.updateItem('company_settings', body.id, body);

      enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });
      loadCompanySettings();
      setLoadingUpdate(false);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const SaveDeliveryAddress = async () => {
    setLoading(true);
    let addItems = [];
    let editItems = [];
    let delItems = [];

    if (deliveryAddressRef.current && deliveryAddressRef.current.length > 0) {
      deliveryAddressRef.current.forEach(data => {
        if (data.statusValue === 3) {
          delItems = [...delItems, data.id];
        }

        if (data.statusValue === 2) {
          editItems = [
            ...editItems,
            {
              id: data.id,
              address_name: data.address_name,
              address_line_1: data.address_line_1,
              address_line_2: data.address_line_2,
              city: data.city,
              state: data.state,
              post_code: data.post_code,
              code: data.code,
              company_id: data.company_id,
            }
          ];
        }

        if (data.statusValue === 1) {
          addItems = [
            ...addItems,
            {
              address_name: data.address_name,
              address_line_1: data.address_line_1,
              address_line_2: data.address_line_2,
              city: data.city,
              state: data.state,
              post_code: data.post_code,
              code: data.code,
              company_id: data.company_id,
              status: 'published'
            }
          ];
        }
      })
    }

    if (delItems.length > 0) {
      try {
        await directus.deleteItems('company_addresses', delItems);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }

    if (editItems.length > 0) {
      try {
        await directus.updateItems('company_addresses', editItems);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }

    if (addItems.length > 0) {
      for (let index = 0; index < addItems.length; index++) {
        var addItem = addItems[index];
        try {
          await directus.createItem('company_addresses', addItem);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
    }

    setLoading(false);
    handleClose();
  }

  const addAddress = currentAddress => {
    if (currentAddress === null) {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = 0;
      data.company_id = dataConfigRef.current.id;
      setDeliveryAddress([data]);
    } else {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = (deliveryAddressRef.current.length > 0 ? deliveryAddressRef.current[deliveryAddressRef.current.length - 1].count : 0) + 1;
      data.company_id = dataConfigRef.current.id;
      setDeliveryAddress([...deliveryAddressRef.current, data]);
    }

    DeliveryAddressCheck();
  };

  const changeInput = (e, dataAddress) => {
    const name = e.target.name;
    const value = e.target.value;

    setDeliveryAddress(
      deliveryAddress.map(data =>
        data.id === dataAddress.id
          ? data.statusValue === 1
            ? { ...dataAddress, statusValue: 1, [name]: value }
            : { ...dataAddress, statusValue: 2, [name]: value }
          : data
      )
    );

    DeliveryAddressCheck();
  }

  const removeAddress = selectedAddress => {  
    if (selectedAddress.statusValue === 1) {
      setDeliveryAddress(deliveryAddressRef.current.filter(data => data.count !== selectedAddress.count));
    } else {
      setDeliveryAddress(deliveryAddressRef.current.map(data => (data.count === selectedAddress.count ? { ...data, statusValue: 3 } : data)));
    }

    DeliveryAddressCheck();
  };

  const DeliveryAddressCheck = () => {
    //eslint-disable-next-line array-callback-return
    if (deliveryAddressRef.current && deliveryAddressRef.current.length > 0) {
      deliveryAddressRef.current.forEach(data => {
        if (data.statusValue !== 3) {
          if (
            data.address_name === null ||
            data.address_name === undefined ||
            data.address_name === '' ||
            data.address_line_1 === undefined ||
            data.address_line_1 === '' ||
            data.city === undefined ||
            data.city === '' ||
            data.state === undefined ||
            data.state === '' ||
            data.post_code === undefined ||
            data.post_code === ''
          ) {
            setDeliveryAddressCheck(true);
          } else {
            setDeliveryAddressCheck(false);
          }
        }
      });
    } else {
      setDeliveryAddressCheck(false);
    }
  };
  
  const handleClose = () => {
    setOpen(false);
    loadCompanySettings();
  };

  return (
    <div >
      {dataConfigRef.current ? dataConfigRef.current.id ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: 'white', paddingLeft: "20px" }}>
            <ListItem style={{ marginTop: "10px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Profile</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Company Name</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Company Name' disabled={!props.config_settings} style={{ width: "100%" }} name="name" onChange={handleChangeInput} value={dataConfigRef.current.name ? dataConfigRef.current.name : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Company Email</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Company Email' disabled={!props.config_settings} style={{ width: "100%" }} name="email" onChange={handleChangeInput} value={dataConfigRef.current.email ? dataConfigRef.current.email : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Company Phone Number</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Company Phone Number' disabled={!props.config_settings} style={{ width: "100%" }} name="phone" onChange={handleChangeInput} value={dataConfigRef.current.phone ? dataConfigRef.current.phone : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>ABN Number</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='ABN Number' disabled={!props.config_settings} style={{ width: "100%" }} name="abn_number" onChange={handleChangeInput} value={dataConfigRef.current.abn_number ? dataConfigRef.current.abn_number : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem style={{ marginTop: "50px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={9} md={9} lg={6}>
                      <div className={classes.header}><span>Address Information</span></div>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5">Billing Address</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Address Line 1</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Address Line 1' disabled={!props.config_settings} style={{ width: "100%" }} name="address_line_1" onChange={handleChangeInput} value={dataConfigRef.current.address_line_1 ? dataConfigRef.current.address_line_1 : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Address Line 2</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Address Line 2' disabled={!props.config_settings} style={{ width: "100%" }} name="address_line_2" onChange={handleChangeInput} value={dataConfigRef.current.address_line_2 ? dataConfigRef.current.address_line_2 : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>City</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='City' disabled={!props.config_settings} style={{ width: "100%" }} name="city" onChange={handleChangeInput} value={dataConfigRef.current.city ? dataConfigRef.current.city : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>State</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='State' disabled={!props.config_settings} style={{ width: "100%" }} name="state" onChange={handleChangeInput} value={dataConfigRef.current.state ? dataConfigRef.current.state : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Postcode</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField data-testid='Postcode' disabled={!props.config_settings} style={{ width: "100%" }} name="postcode" onChange={handleChangeInput} value={dataConfigRef.current.postcode ? dataConfigRef.current.postcode : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem style={{ marginTop: "20px" }}>
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={3}><Typography variant="h5">Delivery Address</Typography></Grid>
                <Grid item xs={6} lg={3}>
                  <AddAddressButton
                    id='show_delivery_address'
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    startIcon={<LocalShippingOutlinedIcon style={{ marginRight: '-3px'}} />}
                  >
                    Show Delivery Address
                  </AddAddressButton>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem style={{ marginBottom: "20px", height: "60px" }}>
              {!loadingUpdate ?
                <SubmitButton
                  id="save_btn"
                  size="small"
                  variant="contained"
                  onClick={() => handleUpdate()}
                  disabled={!props.config_settings}
                >
                  Update
                </SubmitButton>
                : <CircularProgress className={classes.circular} style={{ marginLeft: "0%" }} />}
            </ListItem>
          </List>
        </div>
        : <Typography variant="h5" style={{ fontWeight: "normal", textAlign: "center", marginTop: "20px" }}>No Company Settings</Typography> :
        <CircularProgress className={classes.circular} />
      }

      <Dialog
        id="customers_dialog"
        isopen={`${open}`}
        open={open}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title"><Typography id="customers_dialog_title" component="div" variant="h5">Delivery Address</Typography></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {deliveryAddressRef.current && deliveryAddressRef.current.length > 0 ?
              deliveryAddressRef.current.map((item, index) => {
                const isFirstItem = index === 0;

                return item.statusValue !== 3 ? (
                  <Grid container spacing={2} key={`delivery-address-${item.id}`} className={classes.gridContainer} style={{ marginTop: isFirstItem ? '0px' : '16px' }}>
                    <Grid item xs={12}>
                      <TextField
                        id={`address_name_${item.id}`}
                        label="Delivery Address Name"
                        variant="outlined"
                        fullWidth={true}
                        name="address_name"
                        value={item.address_name}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={`address_line_1_text_${item.id}`}
                        label="Delivery Address Line 1"
                        variant="outlined"
                        fullWidth={true}
                        name="address_line_1"
                        value={item.address_line_1}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={`address_line_2_text_${item.id}`}
                        label="Delivery Address Line 2"
                        variant="outlined"
                        fullWidth={true}
                        name="address_line_2"
                        value={item.address_line_2}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id={`city_text_${item.id}`}
                        label="Delivery City"
                        variant="outlined"
                        fullWidth={true}
                        name="city"
                        value={item.city}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id={`state_text_${item.id}`}
                        label="Delivery State"
                        variant="outlined"
                        fullWidth={true}
                        name="state"
                        value={item.state}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id={`post_code_${item.id}`}
                        label="Delivery Postcode"
                        variant="outlined"
                        fullWidth={true}
                        name="post_code"
                        value={item.post_code}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id={`address_code_${item.id}`}
                        label="Delivery Address Code"
                        variant="outlined"
                        fullWidth={true}
                        name="code"
                        value={item.code}
                        onChange={(e) => changeInput(e, item)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RemoveButton
                        variant="outlined"
                        onClick={() => removeAddress(item)}
                      >
                        Remove
                      </RemoveButton>
                    </Grid>
                  </Grid>
                ) : ''
              })
            : ''}

            <div style={{ margin: '20px 0px 10px 8px' }}>
              <AddAddressButton
                data-testid="add_address_btn"
                variant="outlined"
                onClick={() => addAddress(deliveryAddress ? deliveryAddress : null)}
                startIcon={<img src='/static/images/icons/add-item.svg' alt='add icon' />}
              >
                Add Address
              </AddAddressButton>
            </div>
          </Grid>

        </DialogContent>
        <DialogActions style={{ height: "60px" }}>
          {loading ?
            <CircularProgress className={classes.circular} style={{ marginRight: "1px" }} />
            :
            <div>
              <CancelButton
                id="cancel_btn"
                size="small"
                variant="contained"
                onClick={handleClose}
                style={{ marginRight: '5px' }}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                id='save_btn'
                disabled={deliveryAddressCheck}
                size="small"
                variant="contained"
                onClick={() => SaveDeliveryAddress()}
              >
                Save
              </SubmitButton>
            </div>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CompanySettings;
