import React, { useState, useEffect, useRef } from 'react';
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import directus from '../../services/directus';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import { useStylesEmployees, AddAddressButton, StyledLoadingButton } from './style.js';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '6px',
      marginRight: '6px',
      height: '30px'
    },
  },
})(TextField);

const CssTextField2 = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '35px'
    },
    // '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
    //   marginTop: '8px'
    // },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(15px, -12px) scale(0.75)"
    }
  },
})(TextField);

const Configuration = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataConfig, setDataConfig, dataConfigRef] = useStateRef(null);
  const [passwordOutgoing, setPasswordOutgoing] = useState(null);
  const [poUploadedFile, setPoUploadedFile] = useState(null);
  const [poTemplateDownloadUrl, setPoTemplateDownloadUrl] = useState(null);
  const [soUploadedFile, setSoUploadedFile] = useState(null);
  const [soTemplateDownloadUrl, setSoTemplateDownloadUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [uploadType, setUploadType] = useState('po');
  const [selectedPORequestFileTemplate, setSelectedPORequestFileTemplate, selectedPORequestFileTemplateRef] = useStateRef(false);
  const [selectedSORequestFileTemplate, setSelectedSORequestFileTemplate, selectedSORequestFileTemplateRef] = useStateRef(false);
  const [uploadLoading, setUploadLoading, uploadLoadingRef] = useStateRef(false);
  const [resetPOLoading, setResetPOLoading] = useState(false);
  const [resetSOLoading, setResetSOLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [selectedEncryption, setSelectedEncryption] = useState('');
  const isMounted = useRef(true);
  const [, setSelectedShippingType, selectedShippingTypeRef] = useStateRef('');
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setInitialized(true);
      loadConfiguration();
    }

    return () => {
      isMounted.current = false;
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  // let disable = dataSupplierRef.current.code === '' || dataSupplierRef.current.name === '';

  const loadConfiguration = async () => {
    try {
      var result = await directus.getItems('configuration', { fields: '*, po_request_file_template.*, sales_order_request_file_template.*' });

      var data = result.data[0];
      if (isMounted.current && data) {
        var encryption = data.outgoing_mail_encryption;
        if (encryption) {
          if (encryption === "ssl") setSelectedEncryption({ name: "SSL", value: "ssl" })
          else setSelectedEncryption({ name: "TLS", value: "tls" })
        }

        if (data.max_order_discount) data.max_order_discount = parseFloat(data.max_order_discount);
        if (data.max_order_item_discount_percent) data.max_order_item_discount_percent = parseFloat(data.max_order_item_discount_percent);

        var shippingType = data.shipping_type;
        if (shippingType) {
          if (shippingType === "Account") setSelectedShippingType({ name: "G/L Account", value: "Account" });
          else if (shippingType === "Item") setSelectedShippingType({ name: "Item", value: "Item" });
        }

        if (data.po_request_file_template) setPoUploadedFile(data.po_request_file_template);
        if (data.sales_order_request_file_template) setSoUploadedFile(data.sales_order_request_file_template);
        if (data.purchase_order_template_url) setPoTemplateDownloadUrl(data.purchase_order_template_url);
        if (data.sales_order_template_url) setSoTemplateDownloadUrl(data.sales_order_template_url);
      }
      setDataConfig(data)
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };


  const handleChangeInput = (e, type) => {
    if (type === "encryption") {
      setSelectedEncryption(e);
    } else if (type === "shipping_type") {
      setSelectedShippingType(e);
    } else if (type === "item_discount" || type === "order_discount") {
      const { name, value } = e.target;

      var new_value = value ? parseFloat(value) : 0;
      if (type === "item_discount" && value > 100) return false;
      setDataConfig({ ...dataConfigRef.current, [name]: new_value })
    } else {
      const { name, value } = e.target;
      setDataConfig({ ...dataConfigRef.current, [name]: value })
    }
  }

  const handleChangePassword = (e) => {
    setPasswordOutgoing(e.target.value);
  }

  const enableTestEmailChange = (e) => {
    setDataConfig({ ...dataConfigRef.current, enable_test_email: e.target.checked })
  }

  const enableItemDiscountChange = (e) => {
    setDataConfig({ ...dataConfigRef.current, enable_item_discount_percent: e.target.checked })
  }


  const handleUpdate = async () => {
    if (!loadingUpdate) {
      setLoadingUpdate(true);
      try {
        var data = dataConfigRef.current;
        const body = {
          id: data.id,
          admin_notification_emails: data.admin_notification_emails,
          enable_test_email: data.enable_test_email === true ? 1 : 0,
          test_email: data.test_email,
          tenant_id: data.tenant_id,
          client_id: data.client_id,
          client_secret: data.client_secret,
          client_secret_expiry_date: data.client_secret_expiry_date,
          po_mail_server: data.po_mail_server,
          outgoing_mail_username: data.outgoing_mail_username,
          outgoing_mail_password: passwordOutgoing ? passwordOutgoing : data.outgoing_mail_password,
          outgoing_mail_server: data.outgoing_mail_server,
          outgoing_mail_port: data.outgoing_mail_port,
          outgoing_mail_encryption: selectedEncryption ? selectedEncryption.value : '',
          email_subject_for_invoice_matched: data.email_subject_for_invoice_matched,
          email_subject_for_invoice_not_matched: data.email_subject_for_invoice_not_matched,
          max_order_item_discount_percent: data.max_order_item_discount_percent ? data.max_order_item_discount_percent : 0,
          max_order_discount: data.max_order_discount ? data.max_order_discount : 0,
          enable_item_discount_percent: data.enable_item_discount_percent === true ? 1 : 0,
          shipping_type: selectedShippingTypeRef.current ? selectedShippingTypeRef.current.value : '',
          shipping_number: data.shipping_number
        }

        await directus.updateItem('configuration', body.id, body);
        setPasswordOutgoing(null);
        enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });
        loadConfiguration();
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
      setLoadingUpdate(false);
    }
  }

  const handleClick = url => {
    window.open(url);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    if (file.name.split('.').pop().toLowerCase() !== 'docx') {
      alert("Only .docx files are allowed!");
      e.target.value = '';
      return;
    }

    const form = document.querySelector("#upload_order_template_form");
    const data = new FormData(form);

    if (uploadType === 'po') {
      setSelectedPORequestFileTemplate(data);
    } else {
      setSelectedSORequestFileTemplate(data);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = type => {
    setOpen(true);
    setUploadType(type);
  }

  const uploadTemplate = async () => {
    try {
      setUploadLoading(true);
      const fileResponse = await directus.uploadFiles(uploadType === 'po' ? selectedPORequestFileTemplateRef.current : selectedSORequestFileTemplateRef.current);
      const fileId = fileResponse.data.id;
  
      let body = {
        id: dataConfigRef.current.id,
        po_request_file_template: fileId
      }
  
      if (uploadType === 'so') {
        body = {
          id: dataConfigRef.current.id,
          sales_order_request_file_template: fileId
        }
      }

      await directus.updateItem('configuration', body.id, body);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setUploadLoading(false);
    handleClose();
    loadConfiguration();
  }

  const resetToDefault = async (reset_type) => {
    try {
      const isSO = reset_type === 'so';
      isSO ? setResetSOLoading(true) : setResetPOLoading(true);
  
      let body = {
        id: dataConfigRef.current.id,
        [isSO ? 'sales_order_request_file_template' : 'po_request_file_template']: null
      }

      await directus.updateItem('configuration', body.id, body);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (reset_type === 'so') {
      setSoUploadedFile(null);
      setResetSOLoading(false);
    } else {
      setPoUploadedFile(null);
      setResetPOLoading(false);
    }
  }

  return (
    <div >
      {dataConfigRef.current ? dataConfigRef.current.id ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: 'white', paddingLeft: "20px" }}>
            <ListItem style={{ marginTop: "10px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Admin Settings</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Notification Emails</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Admin Notification Emails' name="admin_notification_emails" onChange={handleChangeInput} value={dataConfigRef.current.admin_notification_emails ? dataConfigRef.current.admin_notification_emails : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>

            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Tests Settings</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "38px" }}>Enable Test Email</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!props.config_settings}
                            name="Test Email Enabled"
                            defaultChecked={dataConfigRef.current.enable_test_email === true ? true : false}
                            onChange={enableTestEmailChange}
                          />
                        }
                        label="Test Email Enabled"
                      />

                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Test Email Address</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Test Email Address' name="test_email" onChange={handleChangeInput} value={dataConfigRef.current.test_email ? dataConfigRef.current.test_email : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            {dataConfigRef.current && dataConfigRef.current.show_invoices ?
              <>
                <ListItem style={{ marginTop: "20px" }}>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h4">Invoice Email Inbox</Typography></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Tenant ID</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Tenant ID' name="tenant_id" onChange={handleChangeInput} value={dataConfigRef.current.tenant_id ? dataConfigRef.current.tenant_id : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Client ID</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Client ID' name="client_id" onChange={handleChangeInput} value={dataConfigRef.current.client_id ? dataConfigRef.current.client_id : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Client Secret</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Client Secret' name="client_secret" onChange={handleChangeInput} value={dataConfigRef.current.client_secret ? dataConfigRef.current.client_secret : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Client Secret Expiry Date</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField type="date" disabled={!props.config_settings} style={{ width: "100%" }} data-testid="client_secret_expiry_date" aria-label='Client Secret Expiry Date' name="client_secret_expiry_date" onChange={handleChangeInput} value={dataConfigRef.current.client_secret_expiry_date ? dataConfigRef.current.client_secret_expiry_date : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Server</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Invoice Mail Server' name="po_mail_server" onChange={handleChangeInput} value={dataConfigRef.current.po_mail_server ? dataConfigRef.current.po_mail_server : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} lg={3}>
                          <SubmitButton
                            id="save_btn"
                            size="small"
                            variant="contained"
                            onClick={() => window.open(dataConfigRef.current.oauth2_redirect_uri)}
                            disabled={!props.config_settings}
                          >
                            Authorise
                          </SubmitButton>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </>
              : ''}
            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Outgoing Emails</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Username</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Username' name="outgoing_mail_username" onChange={handleChangeInput} value={dataConfigRef.current.outgoing_mail_username ? dataConfigRef.current.outgoing_mail_username : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Password</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} type="password" style={{ width: "100%" }} aria-label='Password' name="outgoing_mail_password" onChange={handleChangePassword} value={passwordOutgoing ? passwordOutgoing : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Server</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Outgoing Mail Server' name="outgoing_mail_server" onChange={handleChangeInput} value={dataConfigRef.current.outgoing_mail_server ? dataConfigRef.current.outgoing_mail_server : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Port</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Mail Port' name="outgoing_mail_port" onChange={handleChangeInput} value={dataConfigRef.current.outgoing_mail_port ? dataConfigRef.current.outgoing_mail_port : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Mail Encryption</Typography></Grid>
                    <Grid item xs={6} lg={3}>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={[{ name: "SSL", value: "ssl" }, { name: "TLS", value: "tls" }]}
                        getOptionLabel={(option) => option.name}
                        value={selectedEncryption ? selectedEncryption : null}
                        onChange={(event, newValue) => {
                          handleChangeInput(newValue, "encryption");
                        }}
                        renderInput={(params) =>
                          <CssTextField2
                            {...params}
                            variant="outlined"
                            name="encryption"
                          />}
                      />
                    </Grid>
                  </Grid>
                }
              />

            </ListItem>
            {dataConfigRef.current && dataConfigRef.current.show_invoices ?
              <>
                <ListItem style={{ marginTop: "20px" }}>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={6} lg={3}><Typography variant="h4">Email Subject for Invoice</Typography></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem style={{ marginTop: "-10px", marginBottom: "5px" }}>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={12}><Typography variant="h6">Template tags: {"{{ OrderNumber}}, {{InvoiceNumber}}, {{CompanyName}}, {{CustomerName}}, {{SupplierName}}"}</Typography></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Email Subject for Invoice Matched</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Email Subject for Invoice Matched' name="email_subject_for_invoice_matched" onChange={handleChangeInput} value={dataConfigRef.current.email_subject_for_invoice_matched ? dataConfigRef.current.email_subject_for_invoice_matched : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Email Subject for Invoice Not Matched</Typography></Grid>
                        <Grid item xs={6} lg={3}><CssTextField disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Email Subject for Invoice Not Matched' name="email_subject_for_invoice_not_matched" onChange={handleChangeInput} value={dataConfigRef.current.email_subject_for_invoice_not_matched ? dataConfigRef.current.email_subject_for_invoice_not_matched : ''} variant="outlined" /></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </>
              : ''}

            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Discount Settings</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Max. Order Discount ($)</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField placeholder='0' disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Max. Order Discount' name="max_order_discount" onChange={(e) => handleChangeInput(e, "order_discount")} value={dataConfigRef.current.max_order_discount ? dataConfigRef.current.max_order_discount : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "38px" }}>Enable Order Item Discount Percent</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!props.config_settings}
                            name="Order Item Discount Percent Enabled"
                            defaultChecked={dataConfigRef.current.enable_item_discount_percent === true ? true : false}
                            onChange={enableItemDiscountChange}
                          />
                        }
                        label="Order Item Discount Percent Enabled"
                      />

                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Max. Order Item Discount (%)</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField placeholder='0' disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Max. Order Item Discount %' name="max_order_item_discount_percent" onChange={(e) => handleChangeInput(e, "item_discount")} value={dataConfigRef.current.max_order_item_discount_percent ? dataConfigRef.current.max_order_item_discount_percent : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>


            <ListItem style={{ marginTop: "20px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h4">Shipping Settings</Typography></Grid>
                  </Grid>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}> <Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Shipping Type</Typography></Grid>
                    <Grid item xs={6} lg={3}>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={[{ name: "G/L Account", value: "Account" }, { name: "Item", value: "Item" }]}
                        getOptionLabel={(option) => option.name}
                        value={selectedShippingTypeRef.current ? selectedShippingTypeRef.current : null}
                        onChange={(event, newValue) => {
                          handleChangeInput(newValue, "shipping_type");
                        }}
                        renderInput={(params) =>
                          <CssTextField2
                            {...params}
                            variant="outlined"
                            name="shipping_type"
                          />}
                      />
                    </Grid>
                  </Grid>
                }
              />

            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}><Typography variant="h5" style={{ fontWeight: "normal", lineHeight: "30px" }}>Shipping Number</Typography></Grid>
                    <Grid item xs={6} lg={3}><CssTextField placeholder='0' disabled={!props.config_settings} style={{ width: "100%" }} aria-label='Shipping Number' name="shipping_number" onChange={handleChangeInput} value={dataConfigRef.current.shipping_number ? dataConfigRef.current.shipping_number : ''} variant="outlined" /></Grid>
                  </Grid>
                }
              />
            </ListItem>

            <ListItem style={{ marginTop: "50px" }}>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={9} md={9} lg={6}>
                      <div className={classes.header}><span>Order Templates</span></div>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            {dataConfigRef.current.show_purchase_orders ?
              <>
                <ListItem style={{ marginTop: "20px" }}>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h4">Purchase Order Template</Typography></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '20px'}}>
                            {poUploadedFile && poUploadedFile.data && poUploadedFile.data.full_url ?
                              <Typography
                                variant="h5"
                                className={classes.styledTypography}
                                onClick={() => handleClick(poUploadedFile.data.full_url)}
                              >
                                {poUploadedFile.filename_download}
                              </Typography>
                            : poUploadedFile && poUploadedFile.filename_download ?
                              <Typography
                                variant="h5"
                                className={classes.styledTypography}
                                onClick={() => alert('File not found')}
                              >
                                {poUploadedFile.filename_download}
                              </Typography>
                            :
                              <Typography
                                variant="h5"
                                className={classes.styledTypography}
                                onClick={() => handleClick(soTemplateDownloadUrl)}
                              >
                                Purchase Order File Request Template Default.docx
                              </Typography>
                            }

                            <AddAddressButton
                              id='upload_po_template'
                              variant="outlined"
                              onClick={() => handleOpen('po')}
                              startIcon={<FileUploadIcon style={{ marginRight: '-3px'}} />}
                            >
                              Upload New File
                            </AddAddressButton>
                            
                            <StyledLoadingButton
                              id='reset_so_to_default'
                              variant="outlined"
                              disabled={resetPOLoading || poUploadedFile === null}
                              loadingPosition="start"
                              loading={resetPOLoading}
                              onClick={() => resetToDefault('po')}
                              startIcon={<RestartAltIcon style={{ marginRight: '-3px'}} />}
                            >
                              Reset to Default
                            </StyledLoadingButton>
                          </div>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </>
            : ''}

            {dataConfigRef.current.show_sales_orders ?
              <>
                <ListItem style={{ marginTop: "20px" }}>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={3}><Typography variant="h4">Sales Order Template</Typography></Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={9} md={9} lg={6}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '20px'}}>
                            {soUploadedFile && soUploadedFile.data && soUploadedFile.data.full_url ?
                              <Typography
                                variant="h5"
                                className={classes.styledTypography}
                                onClick={() => handleClick(soUploadedFile.data.full_url)}
                              >
                                {soUploadedFile.filename_download}
                              </Typography>
                            : soUploadedFile && soUploadedFile.filename_download ?
                              <Typography
                                variant="h5"
                                className={classes.styledTypography}
                                onClick={() => alert('File not found')}
                              >
                                {soUploadedFile.filename_download}
                              </Typography>
                            :
                              <Typography
                                variant="h5"
                                className={classes.styledTypography}
                                onClick={() => handleClick(soTemplateDownloadUrl)}
                              >
                                Sales Order File Request Template Default.docx
                              </Typography>
                            }

                            <AddAddressButton
                              id='upload_so_template'
                              variant="outlined"
                              onClick={() => handleOpen('so')}
                              startIcon={<FileUploadIcon style={{ marginRight: '-3px'}} />}
                            >
                              Upload New File
                            </AddAddressButton>
                            
                            <StyledLoadingButton
                              id='reset_so_to_default'
                              variant="outlined"
                              disabled={resetPOLoading || soUploadedFile === null}
                              loadingPosition="start"
                              loading={resetSOLoading}
                              onClick={() => resetToDefault('so')}
                              startIcon={<RestartAltIcon style={{ marginRight: '-3px'}} />}
                            >
                              Reset to Default
                            </StyledLoadingButton>
                          </div>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </>
            : ''}

            <ListItem style={{ marginBottom: "20px", height: "60px" }}>
              {!loadingUpdate ?
                <SubmitButton
                  id="save_btn"
                  size="small"
                  variant="contained"
                  onClick={() => handleUpdate()}
                  disabled={!props.config_settings}
                >
                  Update
                </SubmitButton>
                : <CircularProgress className={classes.circular} style={{ marginLeft: "0%" }} />}
            </ListItem>
          </List>
        </div>
        : <Typography variant="h5" style={{ fontWeight: "normal", textAlign: "center", marginTop: "20px" }}>No Configuration</Typography> :
        <CircularProgress className={classes.circular} />
      }

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Upload File</h2>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form id="upload_order_template_form" className={classes.form} style={{ width: "400px", padding: "10px 0" }}>
            <input
              type="file"
              name="file"
              accept=".docx"
              onChange={e => handleSubmission(e)}
              id="contained-button-file"
              className={classes.input}
            />
          </form>
        </DialogContent>

        <DialogActions style={{ height: "60px", marginRight: "5px" }}>
          {uploadLoadingRef.current ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <div>
              <CancelButton
                id="cancel_btn"
                size="small"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                style={{ marginLeft: "5px" }}
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => uploadTemplate()}
              >
                Save
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Configuration;
