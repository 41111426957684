import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress,
  Divider,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';


let page = 1;

const StockLocations = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [stockLocations, setStockLocations] = useState(null);

  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [, setDataStockLocation, dataStockLocationRef] = useStateRef({
    id: '', code: '', default: false,
  })

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultSortField = {
    "code": { type: "desc", value: false },
    "name": { type: "desc", value: false },
    "default": { type: "desc", value: false },
  };

  const [, setSortField, sortFieldRef] = useStateRef(defaultSortField);

  const [, setSortLoading, sortLoadingRef] = useStateRef(false);
  const isMounted = useRef(true);


  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }

    return () => {
      isMounted.current = false;
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var sort_data = localStorage.getItem("sort_data");

    if (sort_data) {
      sort_data = JSON.parse(sort_data);

      if (sort_data.stock_locations) {
        // Ensure stored data has the same structure as sortField
        let updatedSortField = { ...defaultSortField };

        Object.keys(defaultSortField).forEach(key => {
          if (sort_data.stock_locations[key]) {
            updatedSortField[key] = sort_data.stock_locations[key];
          }
        });

        // Update state with the adjusted sorting field
        setSortField(updatedSortField);
      }
    }

    loadStockLocations();
  }

  const loadStockLocations = async () => {
    const sortFields = ["code", "name", "default"];
    let orderByField = "id";

    // Loop through sort fields and determine orderByField
    for (const field of sortFields) {
      const sort = sortFieldRef.current[field];
      if (sort.value) {
        orderByField = sort.type === "asc" ? field : `-${field}`;
        break; // Stop after finding the first valid sort field
      }
    }

    let filterData = {
      limit: pageNumberRef.current,
      page,
      filter: {
        status: { eq: "published" },
      },
      meta: "filter_count",
      sort: orderByField,
    };

    const result = await directus.getItems("stock_locations", filterData);

    if (isMounted.current) {
      setStockLocations(result.data);
      setTotalPage(result.meta.filter_count);
      setLoadingPagination(false);
      setSortLoading(false);
    }
  };

  const handleClickOpen = (item, mode) => {
    setOpen(true);
    if (mode === "add") {
      setMode('add');
      setTitle('Add Stock Location');
      setText('');
    }
    else if (mode === "edit") {
      setDataStockLocation({
        id: item.id, code: item.code, name: item.name, default: item.default
      })
      setMode('edit');
      setTitle('Edit Stock Location');
      setText('');
    } else {
      setDataStockLocation({
        id: item.id, code: item.code, name: item.name, default: item.default
      })
      setMode('delete');
      setTitle('Delete Stock Location');
      setText('Are you sure?');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setDataStockLocation({
      id: '', code: '', default: false
    })
  };

  const handleChangeInput = (e, type) => {
    const { name, value, checked } = e.target;

    if (type === "checkbox") {
      setDataStockLocation({ ...dataStockLocationRef.current, [name]: checked });
    } else {
      let newValue = value;

      if (name === "code" && value.length > 10) {
        newValue = value.slice(0, 10); // Trim value to max 10 characters
      }

      setDataStockLocation({ ...dataStockLocationRef.current, [name]: newValue });
    }
  }

  const SaveData = async () => {
    setLoading(true);
    try {
      if (mode === 'edit') {
        try {
          var new_data_edit = dataStockLocationRef.current;
          var body_edit = {
            id: new_data_edit.id,
            code: new_data_edit.code,
            name: new_data_edit.name,
            default: new_data_edit.default,
            status: 'published'
          }
          await directus.updateItem('stock_locations', body_edit.id, body_edit);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      else if (mode === 'add') {
        try {
          var new_data = dataStockLocationRef.current;
          var body = {
            code: new_data.code,
            name: new_data.name,
            default: new_data.default,
            status: 'published'
          }
          await directus.createItem('stock_locations', body);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        } catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

      }
      else {
        try {
          await directus.deleteItem('stock_locations', dataStockLocationRef.current.id);
          enqueueSnackbar("Data deleted successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      loadStockLocations();
      setLoading(false);
      handleClose();
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      setLoading(false);
    }
  }


  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadStockLocations();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadStockLocations();
  };

  const sortData = (val) => {
    let sortData = sortFieldRef.current[val];
    if (!sortData) return;
  
    setSortLoading(true);

    let sortType = sortData.type === "desc" ? "asc" : "desc";
    let updatedSortField = { ...defaultSortField, [val]: { value: true, type: sortType } };
  
    let storedSortData = localStorage.sort_data ? JSON.parse(localStorage.sort_data) : {};
    storedSortData.stock_locations = updatedSortField;
    
    localStorage.setItem("sort_data", JSON.stringify(storedSortData));
    setSortField(updatedSortField);
    loadStockLocations();
  };

  return (
    <div>
      {stockLocations ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={4} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("code")}>
                        <Typography noWrap variant="h5">Location Code</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['code'].value === true ?
                            sortFieldRef.current['code'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={5} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("name")}>
                        <Typography noWrap variant="h5">Location Name</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['name'].value === true ?
                            sortFieldRef.current['name'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("default")}>
                        <Typography noWrap variant="h5">Default</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['default'].value === true ?
                            sortFieldRef.current['default'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                  <AddIcon />
                </AddButton>
              </Grid>

            </ListItem>

          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }} >

            {stockLocations.map((item, index) => {
              return (
                <div key={index}>
                  <ListItem style={{ backgroundColor: item.status === "draft" ? "#EBE9E9" : item.status === "inactive" ? "#cdd0d1" : item.status === "deleted" ? "#fee1e0a1" : "white" }}>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={4} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{item.code ? item.code : ''}</Typography>
                          </Grid>
                          <Grid item xs={5} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{item.name ? item.name : ''}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{item.default ? <CheckRoundedIcon style={{ color: "green" }} /> : <CloseRoundedIcon style={{ color: "#b7b7b7" }} />}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(item, 'edit')} >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(item, 'delete')} >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </ListItem>
                  {stockLocations.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      <Dialog
        id="holiday_dialog"
        isopen={`${open}`}
        open={open}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="holiday_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
        <DialogContent>
          <Typography variant="h6">{text ? text : ''}</Typography>
          {mode !== 'delete' ?
            <div>
              <TextField
                id="code"
                label="Code"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="code"
                value={dataStockLocationRef.current.code}
                onChange={handleChangeInput}
              />

              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="name"
                value={dataStockLocationRef.current.name}
                onChange={handleChangeInput}
              />

              <div>
                <FormControlLabel
                  style={{ marginRight: "25px" }}
                  control={
                    <Switch name="default" checked={dataStockLocationRef.current.default === true ? true : false} onClick={(e) => handleChangeInput(e, "checkbox")} />
                  }
                  label="Default"
                />
              </div>
            </div>
            : ''}
        </DialogContent>
        <DialogActions style={{ height: "60px" }}>
          {loading ?
            <CircularProgress className={classes.circular} style={{ marginRight: "1px" }} />
            :
            <div>
              <CancelButton
                id="cancel_btn"
                size="small"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => SaveData()}
              >
                {mode === 'delete' ? 'Delete' : 'Save'}
              </SubmitButton>
            </div>
          }

        </DialogActions>
      </Dialog>

      {stockLocations ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '30px', fontFamily: 'SF Pro' }}
        >
          <Grid item xs={12} style={{ "display": "flex", "justifyContent": "space-between", "flexWrap": "wrap" }}>

            <div>
              <span style={{ paddingRight: '20px' }}>
                Rows per page:
              </span>
              <span style={{ paddingRight: '20px' }}>
                <TextField
                  id="standard-select-currency"
                  select
                  value={pageNumber}
                  onChange={handleChange}
                >
                  {pageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span style={{ paddingRight: '20px' }}>
                {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
              </span>

              <Button
                variant="outlined"
                size="small"
                disabled={page === 1 || loadingPagination}
                className={classes.buttonLeft}
                onClick={() => pageChange(-1)}
              >
                <ArrowBackIosRoundedIcon />
              </Button>

              <Button
                variant="outlined"
                size="small"
                className={classes.buttonRight}
                disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
                onClick={() => pageChange(1)}
              >
                <ArrowForwardIosRoundedIcon />
              </Button>
            </div>
          </Grid>
        </Grid>
        :
        " "}
    </div>
  );
}

export default StockLocations;
