import React, { useState } from 'react';
import AlertDialog from 'src/components/AlertDialog';
import { handleSyncAllItemsPrice, SyncSingleItemPrice } from 'src/views/SalesOrders/SyncItemPrices.js';

export const useSyncDialogs = (itemsRef, selectedCustomerRef, setItems, setSyncLoading, selectedSyncItemRef, orderDate, isUpdated = false) => {
  // Dialog State
  const [dialogSyncAllOpen, setDialogSyncAllOpen] = useState(false);
  const [dialogSyncItemOpen, setDialogSyncItemOpen] = useState(false);

  // Function to sync all items
  const handleClickSyncAll = async () => {
    await handleSyncAllItemsPrice(
      setDialogSyncAllOpen,
      setSyncLoading,
      itemsRef,
      selectedCustomerRef,
      setItems,
      orderDate
    );
  };

  // Function to sync a single item
  const handleClickSyncItem = async () => {
    setDialogSyncItemOpen(false);
    await syncItem(selectedSyncItemRef.current);
  }

  const syncItem = async (itemData) => {
    setSyncLoading(true);
    var updatedItems = await SyncSingleItemPrice(itemData, itemsRef.current, selectedCustomerRef.current, orderDate, isUpdated);
    setItems(updatedItems);
    setSyncLoading(false);
  }

  // Reusable Dialog Components
  const SyncDialogs = () => (
    <>
      <AlertDialog
        open={dialogSyncAllOpen}
        handleClose={() => setDialogSyncAllOpen(false)}
        title="Update Confirmation"
        description="Do you want to sync all items' price and discount?"
        onClickYes={handleClickSyncAll}
        onClickNo={() => setDialogSyncAllOpen(false)}
      />

      <AlertDialog
        open={dialogSyncItemOpen}
        handleClose={() => setDialogSyncItemOpen(false)}
        title="Sync Item Confirmation"
        description="Are you sure you want to sync this item?"
        onClickYes={handleClickSyncItem}
        onClickNo={() => setDialogSyncItemOpen(false)}
      />
    </>
  );

  return {
    SyncDialogs,
    setDialogSyncAllOpen,
    setDialogSyncItemOpen,
  };
};