import React from 'react';
import { Autocomplete, Box } from '@mui/material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { clickCustomerFavorite, resetCustomer, updateSelectedCustomer } from 'src/views/SalesOrders/SalesOrderFunction';
import { checkEnableClick } from 'src/components/ReusableFunction';
import { MenuItem, TextField } from "@material-ui/core";
import { filterOptions } from '../style';

const CustomerAutocomplete = ({
  customerOptionsRef,
  classes,
  selectedCustomerRef,
  isClickFavouriteRef,
  setIsClickFavourite,
  enqueueSnackbar,
  setSelectedCustomer,
  setDeliveryAddressOptionId,
  setSelectedDeliveryAddress,
  changeDeliveryAddressOption,
  itemsRef,
  setDialogSyncAllOpen,
  setCustomerOptions,
  checkCurrentSelectedCustomer = false
}) => {
  return (
    <Autocomplete
      id="customer-options"
      options={customerOptionsRef.current}
      className={classes.autocompleteEditable}
      filterOptions={filterOptions}
      disableClearable
      popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
      getOptionLabel={(option) => option.name ?? ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedCustomerRef.current ?? ''}
      onClose={() => resetCustomer(isClickFavouriteRef, customerOptionsRef, enqueueSnackbar, setIsClickFavourite)}
      sx={{
        '& .MuiAutocomplete-input': {
          textAlign: 'right',
          color: 'rgba(60, 60, 67, 0.6)',
        },
      }}
      onChange={(event, newValue) => {
        const enable_click = checkEnableClick(newValue);

        let currentSelectedCustomer = true;
        if (selectedCustomerRef.current && checkCurrentSelectedCustomer) {
          currentSelectedCustomer = newValue.id !== selectedCustomerRef.current.id;
        }

        if (enable_click && currentSelectedCustomer) {
          updateSelectedCustomer(
            newValue,
            setSelectedCustomer,
            setDeliveryAddressOptionId,
            setSelectedDeliveryAddress,
            changeDeliveryAddressOption,
            itemsRef,
            setDialogSyncAllOpen
          );
        }
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder='Select Customer' />
      )}
      renderOption={(props, option) => {
        const enable_click = checkEnableClick(option);

        let isSelected = false;
        if (selectedCustomerRef.current) {
          isSelected = option.id === selectedCustomerRef.current.id;
        }

        return (
          <MenuItem
            {...props}
            selected={isSelected}
            className={isSelected ? classes.selectedMenuItem : classes.menuItem}
            disabled={!enable_click}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>
                {option.name}
              </span>
              <div onClick={(e) => {
                e.stopPropagation();
                clickCustomerFavorite(option, setIsClickFavourite, customerOptionsRef, setCustomerOptions);
              }}>
                {option.isFavourite ? (
                  <StarRoundedIcon fontSize="small" />
                ) : (
                  <StarBorderRoundedIcon fontSize="small" />
                )}
              </div>
            </Box>
          </MenuItem>
        );
      }}
    />
  );
};

export default CustomerAutocomplete;