import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import EventEmitter from 'src/utils/EventEmitter';
import { useStyles } from './style.js';
import Employees from './Employees';
import Suppliers from './Suppliers.js';
import Customers from './Customers.js';
import Configuration from './Configuration';
import AccountingSystems from './AccountingSystems';
import Categories from './Categories';
import useStateRef from "react-usestateref";
import directus from "../../services/directus";
import Items from './Items';
import CompanySettings from './CompanySettings';
import ApprovalLevel from './ApprovalLevel';
import Integration from './Integration';
import { configurationFields } from 'src/components/ReusableFunction.js';
import DataSynchronization from './DataSynchronization';
import StockLocations from './StockLocations';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Settings = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [, setShowInvoiceTab, showInvoiceTabsRef] = useStateRef(false);
  const [initialized, setInitialized] = useState(false);
  const isMounted = useRef(true);
  const [, setLoading, loadingRef] = useStateRef(false);

  let configuration = JSON.parse(window.localStorage.getItem('configuration'));

  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setLoading(true);
      setInitialized(true);
      getConfigurationSettings();
      var tabSettingsOpen = JSON.parse(window.localStorage.getItem('tab-settings-open'));
      if (tabSettingsOpen) { setValue(tabSettingsOpen); }
    }

    return () => {
      isMounted.current = false;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration', { fields: configurationFields });
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (isMounted.current && result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_invoices === true) {
          setShowInvoiceTab(true);
        }
      }
    }

    setLoading(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTab(newValue);
    window.localStorage.setItem('tab-settings-open', newValue);
  };

  const changeTab = (data) => {
    EventEmitter.emit('changeTab', {
      text: data
    })
  }

  const tabs = [
    { label: "Suppliers", className: classes.customers, component: <Suppliers supplier_settings={configuration.supplier_settings} /> },
    { label: "Employees", className: classes.employees, component: <Employees employee_settings={configuration.employee_settings} /> },
    { label: "Customers", className: classes.customers, component: <Customers customer_settings={configuration.customer_settings} /> },
    { label: "Configuration", className: classes.employees, component: <Configuration config_settings={configuration.config_settings} /> },
    { label: "Integration", className: classes.employees, component: <Integration config_settings={configuration.config_settings} /> },
    showInvoiceTabsRef.current && { label: "Accounting Systems", className: classes.employees, component: <AccountingSystems config_settings={configuration.config_settings} /> },
    { label: "Categories", className: classes.employees, component: <Categories config_settings={configuration.config_settings} /> },
    { label: "Items", className: classes.employees, component: <Items /> },
    { label: "Company Settings", className: classes.employees, component: <CompanySettings config_settings={configuration.config_settings} /> },
    { label: "Data Synchronization", className: classes.employees, component: <DataSynchronization config_settings={configuration.config_settings} /> },
    { label: "Stock Locations", className: classes.employees, component: <StockLocations config_settings={configuration.config_settings} /> },
    configuration.enable_approvals && { label: "Approval Level", className: classes.employees, component: <ApprovalLevel /> }
  ].filter(Boolean);

  return (
    <Page className={classes.root} title="Settings" style={{ paddingTop: "0px" }}>
      {loadingRef.current ?
        ''
        :
        <Container maxWidth={true} style={{ padding: "0px" }}>
          <div className="control-pane">
            <div className="control-section">
              <AppBar position="static" className={classes.Appbar}>
                <Tabs
                  variant='scrollable'
                  scrollButtons='auto'
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  {tabs.map((tab, index) => (
                    <Tab key={index} className={tab.className} label={tab.label} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </AppBar>
              {tabs.map((tab, index) => (
                <TabPanel key={index} value={value} index={index} className={classes.tab}>
                  {tab.component}
                </TabPanel>
              ))}
            </div>

          </div>
        </Container>
      }
    </Page>
  );
};

export default Settings;