import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({
    title,
    description,
    onClickNo,
    onClickYes,
    open
}) {
    // Prevent page reload when the dialog is open
    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (open) {
                event.preventDefault();
                event.returnValue = ""; // Standard for modern browsers
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [open]);

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown // Prevent closing with ESC key
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
        >
            <DialogTitle id="alert-dialog-title" style={{ fontSize: "16px" }}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClickNo} color="inherit">
                    No
                </Button>
                <Button onClick={onClickYes} color="success" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
